import React from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { color, breakpoint } from 'utils/style/variables';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Dashboard from 'components/Dashboard/Dashboard';
import CampaignExplorer from 'components/Reporting/CampaignExplorer/CampaignExplorer';
import CampaignExplorernew from 'components/Reporting/CampaignExplorerNew/CampaignExplorer';
import BOA from 'components/Optimisation/BOA/BOA';
import Boainfo from 'components/Optimisation/BOA/Boainfo';
import Viking from 'components/Optimisation/Viking/Viking';
import Vikinginfo from 'components/Optimisation/Viking/Vikinginfo';
import Xposure from 'components/Reporting/Xposure/Xposure';
import EspressoProfiling from 'components/Reporting/EspressoProfiling/EspressoProfiling';
//import Navigator from 'components/Optimisation/Libra/Libra';
import GoogleSearchAlgo from 'components/Optimisation/GoogleSearchAlgo/GoogleSearchAlgo';
import GoogleSearchAlgoinfo from 'components/Optimisation/GoogleSearchAlgo/GoogleSearchAlgoinfo';
import SegmentSharer from 'components/CampaignSetUp/SegmentSharer/SegmentSharer';
import ForecastingOutcome from 'components/Reporting/ForecastingOutcome/ForecastingOutcome';
import ForecastingOptions from 'components/Reporting/ForecastingOutcome/ForecastingOptions';
import WeatherAlgoinfo from 'components/CampaignSetUp/WeatherAlgo/WeatherAlgoinfo';
import AutoScaninfo from 'components/CampaignSetUp/Autoscan/AutoScaninfo';
import CampaignExplorerYoutube from 'components/Reporting/CampaignExplorerYoutube/CampaignExplorerYoutube';
import ContextualInsights from 'components/Reporting/ContextualInsights/ContextualInsights';
import IABContextualSegments from 'components/Reporting/IABContextualSegments/ContextualSegments';
import ContextualToolBox from 'components/Reporting/ContextualInsights/ContextualToolbox';

export class Layout extends React.Component {
  render() {
    return (
      <StyledLayout>
        <Header />
        <Main>
          {/* <StyledContainer fluid="true"> */}
          <StyledContainer fluid>
            <Switch>
              <Route
                path="/reporting/campaign-explorer"
                exact
                component={CampaignExplorer}
              />
              <Route
                path="/reporting/campaign-explorernew"
                exact
                component={CampaignExplorernew}
              />
              <Route path="/reporting/xposure" exact component={Xposure} />
              <Route
                path="/reporting/forecastingoutcome"
                exact
                component={ForecastingOutcome}
              />
              <Route
                path="/reporting/forecastingoptions"
                exact
                component={ForecastingOptions}
              />
              <Route
                path="/reporting/espressoprofiling"
                exact
                component={EspressoProfiling}
              />
              <Route
                path="/reporting/campaign-explorer-video"
                exact
                component={CampaignExplorerYoutube}
              />
              <Route
                path="/reporting/contextual-insights"
                exact
                component={ContextualInsights}
              />
              <Route
                path="/campaignsetup/iab-contextual-segments"
                exact
                component={IABContextualSegments}
              />
              <Route
                path="/contextual-toolbox"
                exact
                component={ContextualToolBox}
              />
              <Route path="/optimisation/boa" exact component={BOA} />
              <Route path="/optimisation/boainfo" exact component={Boainfo} />
              <Route path="/optimisation/viking" exact component={Viking} />
              <Route
                path="/optimisation/vikinginfo"
                exact
                component={Vikinginfo}
              />
              {/* <Route path="/optimisation/libra" exact component={Navigator} /> */}
              <Route
                path="/optimisation/googlesearchalgo"
                exact
                component={GoogleSearchAlgo}
              />
              <Route
                path="/optimisation/googlesearchalgoinfo"
                exact
                component={GoogleSearchAlgoinfo}
              />
              <Route
                path="/campaign/weatheralgoinfo"
                exact
                component={WeatherAlgoinfo}
              />
              <Route
                path="/campaign/autoscaninfo"
                exact
                component={AutoScaninfo}
              />
              <Route
                path="/campaign/segment-sharer"
                exact
                component={SegmentSharer}
              />
              <Route path="/dashboard" exact component={Dashboard} />
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </StyledContainer>
        </Main>
        <Footer />
      </StyledLayout>
    );
  }
}

const StyledLayout = styled.div`
  min-height: 100vh;
  background-color: ${color.grey.bg};
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
`;

const StyledContainer = styled(Container)`
  height: 100%;
  @media (${breakpoint.up.xl}) {
    width: 110rem;
  }

  @media (${breakpoint.down.md}) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export default withRouter(Layout);
