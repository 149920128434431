import React from 'react';
import styled from 'styled-components';
import config from 'config';
import { color } from 'utils/style/variables';

const Footer = () => {
  return (
    <StyledFooter>
      <span>&copy; 2021 Xaxis. All Rights Reserved</span>
      <Divider>|</Divider>
      <span>Version {config.version}</span>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: ${color.white};
  max-width: 100%;
  flex: 0 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
`;

const Divider = styled.div`
  display: inline-block;
  color: ${color.primary.default};
  margin: 0 0.5rem;
`;

export default Footer;
