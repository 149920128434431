import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Container, Row, Col } from 'react-bootstrap';
import { color, font, shadow } from 'utils/style/variables';
import Select from 'react-select';
import styled from 'styled-components';
import api from 'axios-instance';
import chartJsConfig from './report-config';
import interpolateColors from 'utils/color-generator';
import Spinner from 'components/UI/Spinner/Spinner';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import size from 'lodash/size';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCoins,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';

class BoaReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartsDisplayed: false,
      chartDataLoading: false,
      charts: [],
      jobIdList: [],
      COLORS: [],
      overallKPI: null,
      lift: null,
      lastUpdate: moment(),
    };
    this.getJobListHandler();
  }

  styles = {
    container: provided => ({ ...provided }),
    control: provided => ({
      ...provided,
      transition: 'all 0.3s',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      '&:hover': {
        border: '1px solid #adb5bd',
      },
      marginBottom: '1rem',
    }),
  };

  chartDataHandler = data => {
    const chartData = data.data[0].M;
    const labels = this.getDates(chartData.start_date.N, chartData.end_date.N);
    const colorRangeInfo = {
      colorStart: 0.4,
      colorEnd: 1,
      useEndAsStart: false,
    };
    /* Create color array */
    const COLORS = interpolateColors(
      size(chartData.previous_iterations.M),
      interpolateRdYlBu,
      colorRangeInfo
    );

    this.setState({ COLORS: COLORS });

    const performanceDatasets = Object.entries(
      chartData.previous_iterations.M
    ).map(([key, value], index) => {
      return {
        ...chartJsConfig,
        label: key,
        data: value.M.cumulative_performance.L.map(item => item.N),
        backgroundColor: COLORS[index],
        borderColor: COLORS[index],
        pointBorderColor: 'white',
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: COLORS[index],
        pointHoverBorderColor: 'white',
      };
    });

    const allocationDatasets = Object.entries(
      chartData.previous_iterations.M
    ).map(([key, value], index) => {
      return {
        ...chartJsConfig,
        label: key,
        data: value.M.cumulative_budget_allocation.L.map(item => item.N),
        backgroundColor: COLORS[index],
        // if an alpha channel is needed, the string can be changed:
        // .replace(')', ', 0.8)')
        // .replace('rgb', 'rgba'),
        borderColor: COLORS[index],
        pointBorderColor: 'white',
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: COLORS[index],
        pointHoverBorderColor: 'white',
        fill: true,
      };
    });

    return [
      {
        name: `Outcome Performance (${chartData.outcome.S})`,
        labels: labels,
        datasets: performanceDatasets,
        options: {},
      },
      {
        name: 'Budget Allocation',
        labels: labels,
        datasets: allocationDatasets,
        options: {
          scales: {
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
    ];
  };

  getDates = (startDate, stopDate) => {
    let dateArray = [];
    let currentDate = moment(startDate);
    const endDate = moment(stopDate);
    while (currentDate <= endDate) {
      dateArray.push(moment(currentDate).format('DD-MM-YYYY'));
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  };

  changeHandler = e => {
    this.setState({ chartsDisplayd: false, charts: [] });
    this.getJobDataHandler(e.value);
    this.setState({ chartsDisplayed: true });
  };

  getJobDataHandler = jobId => {
    this.setState({ chartDataLoading: true });
    Auth.currentSession()
      .then(authResponse => {
        api
          .get('/boa/getJobData', {
            params: {
              jobId: jobId,
            },
          })
          .then(response => {
            this.setState({ chartDataLoading: false });
            const chartData = this.chartDataHandler(response);
            this.setState({ charts: chartData });
            this.setState({
              overallKPI:
                response.data[0].M.overall_cum_performance.L[
                  response.data[0].M.overall_cum_performance.L.length - 1
                ].N,
              lift: response.data[0].M.lift.N,
              lastUpdate: moment(
                response.data[0].M.previous_iterations.M[
                  Object.keys(response.data[0].M.previous_iterations.M)[0]
                ].M.last_update.N,
                'YYYYMMDD'
              ),
            });
          });
      })
      .catch(error => {
        this.setState({ chartDataLoading: false });
        console.log(error);
      })
      .catch(authError => {
        this.setState({ chartDataLoading: false });
        console.log(
          'error trying to receive currentAuthenticatedUser',
          authError
        );
      });
  };

  getJobListHandler = () => {
    Auth.currentSession()
      .then(authResponse => {
        api
          .get('/boa/getJobIdList', {
            params: {
              //userId: this.props.email.toLowerCase(),
              userEmail: this.props.email.toLowerCase(),
            },
          })
          .then(response => {
            const jobIds = response.data.jobId.map(id => {
              return {
                value: id,
                label: 'JobId: ' + id,
              };
            });
            this.setState({ jobIdList: jobIds });
          });
      })
      .catch(error => {
        console.log(error);
      })
      .catch(authError => {
        console.log(
          'error trying to receive currentAuthenticatedUser',
          authError
        );
      });
  };

  render() {
    const chartsArray = this.state.charts.map(item => {
      return (
        <Col key={item.name}>
          <H3>{item.name}</H3>
          <Line data={item} options={item.options} />
        </Col>
      );
    });

    const overallPerformance = (
      <OverallPerformanceContainer>
        <Row>
          <Col>
            <OPCHeadline>Overall Performance since BOA launch</OPCHeadline>
          </Col>
        </Row>
        <Row>
          <OPCCol>
            <OPCIcon>
              <Icon icon={faCoins} size="lg" />
            </OPCIcon>
            <OPCValue>{this.state.overallKPI}</OPCValue>
            <OPCValueHeadline>Overall CPC</OPCValueHeadline>
          </OPCCol>
          <OPCCol>
            <OPCIcon>
              <Icon icon={faChartBar} size="lg" />
            </OPCIcon>
            <OPCValue
              color={
                this.state.lift > 0 ? color.primary.green : color.primary.red
              }
            >
              {this.state.lift}
            </OPCValue>
            <OPCValueHeadline>Change vs Baseline</OPCValueHeadline>
          </OPCCol>
          {/* <OPCCol> */}
          {/* <OPCIcon> */}
          {/* <Icon icon={faChartBar} size="lg" inverse /> */}
          {/* </OPCIcon> */}
          {/* <OPCValue>-0.12</OPCValue> */}
          {/* <OPCValueHeadline>Change since last budget update</OPCValueHeadline> */}
          {/* </OPCCol> */}
          <OPCCol>
            <OPCIcon>
              <Icon icon={faCalendarAlt} size="lg" />
            </OPCIcon>
            <OPCValue>{this.state.lastUpdate.format('DD.MM.YYYY')}</OPCValue>
            <OPCValueHeadline>Last Budget update</OPCValueHeadline>
          </OPCCol>
        </Row>
      </OverallPerformanceContainer>
    );

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    return (
      <>
        <Select
          styles={this.styles}
          options={this.state.jobIdList}
          onChange={this.changeHandler}
        />
        {this.state.chartDataLoading ? (
          spinnerContainer
        ) : this.state.chartsDisplayed ? (
          <Container>
            <Row>
              <Col>{overallPerformance}</Col>
            </Row>
            <Row>
              <Col>
                <Row>{chartsArray}</Row>
              </Col>
            </Row>
          </Container>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    email: state.auth.userEmail,
  };
};

const H3 = styled.h3`
  text-align: center;
  margin-top: 2rem;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const OverallPerformanceContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const OPCHeadline = styled.h1`
  margin: 1rem 0 0.5rem 0;
`;

const OPCValueHeadline = styled.h5`
  text-align: center;
  margin-bottom: 1rem;
`;

const OPCCol = styled(Col)`
  background-color: ${color.white};
  color: ${color.grey.c900};
  border-radius: 4px;
  margin: 1rem;
  padding: 1rem;
  box-shadow: ${shadow.card};
`;

const OPCValue = styled.div`
  color: ${props => props.color || color.grey.c900};
  text-align: center;
  font-size: 32px;
  font-family: ${font.gothamBold};
`;

const OPCIcon = styled.div`
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: ${color.grey.bg};
  color: ${color.primary.default};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 1rem;
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)``;

export default connect(mapStateToProps)(BoaReport);
