import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Tab, Nav, Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color, transition } from 'utils/style/variables';
import Form from './Form/Form';
import seats from 'utils/data/seatIds';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import BoaReport from './Report/Report';
import Boainfo from './Boainfo';
import api from 'axios-instance';

export class BOA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.1.1',
      form: {
        fields: [
          {
            name: 'jobName',
            initialValue: '',
            type: 'text',
            required: true,
            config: {
              placeholder: 'Job Name',
            },
          },
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'advertiser',
            initialValue: '',
            type: 'number',
            required: true,
            config: {
              placeholder: 'Advertiser',
            },
          },
          {
            name: 'insertionOrderId',
            initialValue: '',
            type: 'number',
            required: true,
            config: {
              placeholder: 'Insertion Order ID',
            },
          },
          {
            name: 'outcome',
            initialValue: '',
            type: 'select',
            required: false,
            config: {
              placeholder: 'Outcome',
              options: [
                { value: 'CPC', label: 'CPC' },
                { value: 'CTR', label: 'CTR' },
                { value: 'CPA', label: 'CPA' },
                { value: 'CONVERSION_RATE', label: 'Conversion Rate' },
                { value: 'CPCV', label: 'CPCV' },
                { value: 'VCR', label: 'VCR' },
              ],
            },
          },
          {
            name: 'startDate',
            initialValue: moment(),
            type: 'date',
            required: true,
            config: {
              dateRange: false,
              start: null,
              end: null,
              placeholder: 'Start Date',
              explanation:
                'Specify the same date as IO start date',
            },
          },
          {
            name: 'ioDailyBudget',
            initialValue: '',
            type: 'currency',
            required: true,
            config: {
              placeholder: 'IO Daily Budget',
            },
          },
          {
            name: 'lineItems',
            initialValue: [],
            type: 'tags',
            required: true,
            config: {
              placeholder: 'Line Item(s)',
            },
          },
          //{
            //name: 'initialAllocation',
            //initialValue: [],
            //type: 'tags',
            //required: false,
            //config: {
              //placeholder: 'Initial Allocation',
              //explanation:
                //'Initial allocation if you want to specify, else leave it blank. If provided, sum of all should be equal to IO Daily budget.',
            //},
          //},
          //{
            //name: 'minimumExploration',
            //initialValue: 0,
            //type: 'slider',
            //required: true,
            //config: {
              //placeholder: 'Minimum Spend',
              //explanation: '',
              //min: 0,
              //max: 'ioDailyBudget',
              //step: 0.01,
            //},
          //},
          //{
            //name: 'period',
            //initialValue: '',
            //type: 'text',
            //required: false,
            //config: {
              //placeholder: 'Period',
              //explanation:
                //'Number of hours between budget optimisations, e.g. for daily adjustments input 24.'
            //},
          //}, 
          //{
            //name: 'movingWindow',
            //initialValue: 1,
            //type: 'slider',
            //required: false,
            //config: {
              //placeholder: 'Moving Window',
              //explanation:
                //'Number of periods.',
              //min: 1,
              //max: 14,
              //step: 1,
            //},
          //},
          //{
            //name: 'waitingWindow',
            //initialValue: 0,
            //type: 'slider',
            //required: false,
            //config: {
              //placeholder: 'Waiting Window',
              //explanation:
                //'Number of periods.',
              //min: 0,
              //max: 14,
              //step: 1,
            //},
          //},
          // not needed right now, keeping for future use
          //{
           //name: 'iteration',
           //initialValue: 0,
           //type: 'text',
           //required: true,
           //config: {
           //placeholder: 'Iteration',
           //},
           //},
          
          //{
            //name: 'rate',
            //initialValue: 0,
            //type: 'slider',
            //required: false,
            //config: {
              //placeholder: 'Rate',
              //min: 0,
              //max: 1,
              //step: 0.1,
            //},
          //}, 
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    jobName: Yup.string().required('Specify a Job Name'),
    seatId: Yup.string().required('Choosing a seat ID is required.'),
    advertiser: Yup.array()
      .of(Yup.number())
      .required('You must ad at least one Advertiser ID'),
    insertionOrderId: Yup.number()
      .typeError('The insertion oder ID must be a number.')
      .positive('The insertion order ID must be positive.')
      .integer('The insertion order ID must be an integer.')
      .required('Adding an insertion order ID is required.'),
    startDate: Yup.date().required('A start date is required.'),
    ioDailyBudget: Yup.number().required(
      'Choosing an outcome type is required.'
    ),
    lineItems: Yup.array()
      .of(Yup.number().positive())
      .required('You must ad at least one line item ID.'),
    initialAllocation: Yup.array().of(Yup.number().positive()),
    //minimumExploration: Yup.number()
      //.positive()
      //.required('Choosing an outcome type is required.'),
    //movingWindow: Yup.number().positive(),
    //waitingWindow: Yup.number().required(
      //'Choosing an outcome type is required.'
    //),
    iteration: Yup.number().required('Choosing an iteration is required.'),
    outcome: Yup.string(),
    //period: Yup.number(),
    //rate: Yup.number().required('Choosing a rate is required.'),
  });

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing a seat ID is required.'),
  });

  createFormData = (values, email) => {
    // console.log('values', values);
    //var lineItemsArray = values.lineItems;
    //console.log(lineItemsArray);
    //lineItemsArray.forEach(function(entry) {
      //console.log(parseInt(entry));
    //});
    //console.log(lineItemsArray);
    return {
      jobId: values.jobId,
      jobName: values.jobName,
      seatId: values.seatId.value,
      advertiser: values.advertiser,
      insertionOrderId: values.insertionOrderId,
      startDate: values.startDate,
      //startDate: values.startDate.toISOString().split('T')[0],
      ioDailyBudget: parseFloat(values.ioDailyBudget),
      lineItems: values.lineItems,
      //initialAllocation: values.initialAllocation,
      initialAllocation: '',
      //minimumExploration: parseFloat(values.minimumExploration),
      minimumExploration: parseFloat(values.ioDailyBudget) * 0.05,
      //movingWindow: parseInt(values.movingWindow),
      movingWindow: 14,
      //waitingWindow: parseInt(values.waitingWindow),
      waitingWindow: 2,
      iteration: 0,
      //iteration: parseInt(values.iteration),
      outcome: values.outcome.value,
      period: 24,
      //rate: parseFloat(values.rate),
      rate: 0.3,
      userEmail: email,
    };
  };

  submitHandler = values => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    // console.log('submitting...', formData);
    Auth.currentAuthenticatedUser()
      .then(response => {
        this.postDataHandler(formData);
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = formData => {
    api
      //.post('/boa/create', formData)
      .put('/boa/putConfig', formData)
      .then(response => {
        console.log(response);
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobName: response.data.jobName,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Optimisation',
      levels: [
        {
          name: 'Budget Optimisation Algorithm',
          link: '/optimisation/boa',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
          <Card className="xtCard">
            <Card.Body>
              <h1>Budget Optimisation Algorithm</h1>
              <p>
                Optimise your budget across multiple line itmes depending on their performance, maximise overall performance.
              </p>
              <Tab.Container defaultActiveKey="create" id="boa-tab">
                <StyledNav>
                  <StyledNavItem>
                    <StyledNavLink eventKey="create">Create</StyledNavLink>
                  </StyledNavItem>
                  {/*<StyledNavItem>
                    <StyledNavLink eventKey="report">Report</StyledNavLink>
                  </StyledNavItem>*/}
                  <StyledNavItem>
                    <StyledNavLink eventKey="boainfo">About</StyledNavLink>
                  </StyledNavItem>
                </StyledNav>
                <Tab.Content>
                  <Tab.Pane id="defaultTab" eventKey="create">
                    {this.state.hasSubmitted ? (
                      response
                    ) : (
                      <React.Fragment>
                        <p>
                          Please fill out this form in order to create your BOA
                          request.
                        </p>
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      </React.Fragment>
                    )}
                  </Tab.Pane>
                  <Tab.Pane id="reportTab" eventKey="report">
                    <BoaReport />
                  </Tab.Pane>
                  <Tab.Pane id="infoTab" eventKey="boainfo">
                    <Boainfo />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const StyledNav = styled(Nav)`
  border-bottom: 1px solid ${color.grey.c200};
  margin-bottom: 2rem;
`;

const StyledNavItem = styled(Nav.Item)`
  width: 12rem;
  height: 50px;
  margin: 0 1rem 0 1rem;
  margin-bottom: -1px;
  border: 1px solid ${color.grey.c200};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: ${transition.default};
  background-color: ${color.white};
`;

const StyledNavLink = styled(Nav.Link)`
  background-color: ${color.grey.c100};
  border-top: 2px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: ${color.grey.c600};
  height: 100%;
  height: 48px;
  transition: ${transition.default};

  &:hover {
    background-color: ${color.white};
  }

  &.active {
    color: ${color.primary.default};
    background-color: ${color.white};
    border-bottom-color: ${color.white};
    border-top: 2px solid ${color.primary.default};
    height: 49px;
  }
`;

export default connect(mapStateToProps)(BOA);
