import React, { useState } from 'react';

import moment from 'moment';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const DatePickerWithFormik = ({ form: { setFieldValue, values } }) => {
  const [focused, setFocusedInput] = useState(null);

  return (
    <SingleDatePicker
      date={values.startDate}
      onDateChange={date => {
        setFieldValue('startDate', date);
      }}
      focused={focused ? focused.focused : focused}
      onFocusChange={({ focused }) => setFocusedInput({ focused })}
      displayFormat={() => moment.localeData().longDateFormat('LL')}
      id="StartDate"
    />
  );
};

export default DatePickerWithFormik;
