import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { color, transition } from 'utils/style/variables';
import { faTable, faFunnelDollar } from '@fortawesome/free-solid-svg-icons';
import SubNav from './SubNav/SubNav';

const MainNav = (props) => {
  const campaignSubnav = [
    // {
    //   external: false,
    //   // active: false,
    //   link: '/optimisation/boa',
    //   title: 'Segment Sharer (Coming Soon)',
    //   icon: faTable,
    // },
    {
      external: false,
      active: true,
      link: '/campaignsetup/iab-contextual-segments',
      title: 'IAB Contextual Segments',
      icon: faTable,
    },
    {
      divider: true,
    },
    {
      external: true,
      link: 'https://emeaminsights.mplatform.com',
      title: '[m]insights',
    },
    {
      external: true,
      link: 'https://platform.inca-global.com/',
      title: 'INCA',
    },
    {
      external: true,
      active: true,
      link: 'https://autoscan.ihub.link/login',
      title: 'AutoScan',
      icon: faTable,
    },
  ];

  const reportingSubnav = [
    {
      external: false,
      active: true,
      link: '/reporting/campaign-explorer',
      title: 'Campaign eXplorer',
      icon: faTable,
    },
    {
      external: false,
      active: true,
      link: '/reporting/xposure',
      title: 'Exposure Time Report',
      icon: faTable,
    },
    {
      external: false,
      active: true,
      link: '/reporting/espressoprofiling',
      title: 'Espresso Profiling',
      icon: faTable,
    },
    {
      external: false,
      active: true,
      link: '/reporting/campaign-explorer-video',
      title: 'Campaign Explorer Video',
      icon: faTable,
    },
    {
      external: false,
      active: true,
      link: '/reporting/contextual-insights',
      title: 'Contextual Insights',
      icon: faTable,
    },
    // {
    //   divider: true,
    // },
    // {
    //   external: true,
    //   link:
    //     'https://xaxistech.atlassian.net/wiki/spaces/GP/pages/422477827/The+Trader+Dashboard',
    //   title: 'Xaxis Global Trader Dashboard (XGTD)',
    // },
    // {
    //   external: true,
    //   link: 'https://platform.datorama.com/',
    //   title: '[m]report',
    // }
  ];

  const optimisationSubnav = [
    // {
    //   external: false,
    //   active: true,
    //   link: '/optimisation/boa',
    //   title: 'BOA (test it now!)',
    //   icon: faFunnelDollar,
    // },
    {
      external: false,
      active: true,
      link: '/optimisation/vikinginfo',
      title: 'Web Analytics Optimisation Algorithm',
      icon: faFunnelDollar,
    },
    {
      external: false,
      active: true,
      link: '/optimisation/googlesearchalgoinfo',
      title: 'Google Search Trends Algorithm',
      icon: faFunnelDollar,
    },
    {
      divider: true,
    },
    {
      external: true,
      link: 'https://copilot.xaxisdemand.com',
      title: 'Copilot',
    },
  ];

  const supplySubnav = [
    // {
    //   divider: true,
    // },
    {
      external: true,
      link: 'https://app.powerbi.com/groups/fddb5491-bdf9-4524-b036-858d352179fa/reports/7302cbbf-99f1-4793-99a6-2592415b009f/ReportSection542129d620a0dac00141',
      title: 'Market Client Safety',
    },
    {
      external: true,
      link: 'https://app.powerbi.com/groups/fddb5491-bdf9-4524-b036-858d352179fa/reports/3db788b3-1eb4-4e7a-96d5-7464c366d3d9/ReportSection2f7ed182ae52d0e5a6b5',
      title: 'Supply Path Optimisation',
    },
    {
      external: true,
      link: 'https://app.powerbi.com/groups/me/apps/ab87aa11-88ce-43ef-bd86-122cf48d5e89/reports/6bebc850-a842-4b35-b6d0-00af5da56968/ReportSectionb122828f8622a03bdeb2',
      title: 'Headlight Campaign Setup',
    },
    {
      external: true,
      link: 'https://app.powerbi.com/groups/me/apps/ab87aa11-88ce-43ef-bd86-122cf48d5e89/reports/e73ce45c-a1ce-4c35-90b4-012abba07f2d/ReportSectionccdc981d4ef59d1252ae',
      title: 'Domain Report',
    },
    {
      external: true,
      link: 'https://app.powerbi.com/groups/me/apps/ab87aa11-88ce-43ef-bd86-122cf48d5e89/reports/ed6b0274-b277-4672-a509-fbfea8ad23d8/ReportSection',
      title: 'Margin Management',
    },
  ];

  return (
    <StyledNav>
      <ListGroup>
        <ListItem>
          <StyledLink
            activeClassName="active"
            to="/campaign"
            onClick={props.clickedCampaign}
          >
            Campaign Set-up <Icon icon={faAngleDown} />
          </StyledLink>
          {props.showCampaignSubnav && <SubNav config={campaignSubnav} />}
        </ListItem>
        <ListItem>
          <StyledLink
            activeClassName="active"
            to="/optimisation"
            onClick={props.clickedOptimisation}
          >
            Optimisation <Icon icon={faAngleDown} />
          </StyledLink>
          {props.showOptimisationSubnav && (
            <SubNav config={optimisationSubnav} />
          )}
        </ListItem>
        <ListItem>
          <StyledLink
            activeClassName="active"
            to="/reporting"
            onClick={props.clickedReporting}
          >
            Reporting <Icon icon={faAngleDown} />
          </StyledLink>
          {props.showReportingSubnav && <SubNav config={reportingSubnav} />}
        </ListItem>
        <ListItem>
          <StyledLink
            activeClassName="active"
            to="/supply"
            onClick={props.clickedSupply}
          >
            Dashboards
            <Icon icon={faAngleDown} />
          </StyledLink>
          {props.showSupplySubnav && <SubNav config={supplySubnav} />}
        </ListItem>
      </ListGroup>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ListGroup = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  position: relative;
  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const StyledLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.8rem 1.5rem;
    font-size: 1.4rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.8rem;
  margin-top: 0.2rem;
`;

export default MainNav;
