import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';

import classes from './UserDropdown.module.scss';
import blueBg from 'assets/img/head_bg_sm_xaxis.jpg';

export const UserDropdown = (props) => {

  return (
    <Card className={classes.UserDropdown}>
      <Card.Img className={classes.Background} variant="top" src={blueBg} />
      <Card.Body className={classes.Body}>
        <Card.Title className={classes.Title}>
          <div className={classes.Name}>
            Logged in as:
            </div>
        </Card.Title>
        <Card.Text as="div" className={classes.TextBox}>
          {props.userEmail}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    userEmail: state.auth.userEmail,
  };
};

export default connect(mapStateToProps)(UserDropdown);