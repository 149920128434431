import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';

import weatherImage from '../../../assets/img/weather.jpg';

const WeatherAlgoinfo = () => {
  const subheader = {
    main: 'Campaign Set-up',
    levels: [
      {
        name: 'Weather Algorithmn Information',
        link: '/optimisation/weatheralgoinfo',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={weatherImage} />
              <Card.Body>
                <CardTitle>Weather Targeting (COMING SOON!)</CardTitle>
                <Card.Text>
                  A proprietary solution for geo targeting your campaigns
                  depending on weather conditions and forecasts.
                  <LastPara1></LastPara1>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>How Weather Targeting works</CardTitle>
                <Card.Text>
                  <p>
                    Many Clients require their campaigns to be activated only on
                    specific weather conditions in order to achieve maximum
                    performance. For example, sun protection product campaign
                    will work best during sunny weather. For more examples of
                    when weather becomes an important factor for product sales /
                    advertising efficiency see{' '}
                    <a
                      href="/assets/files/weatherdocs/Weather_for_Advertising_ReasearchResults.docx"
                      alt="Weather for advertising research results"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>
                    Up until now Xaxis teams had to rely on third party data
                    providers in order to setup and execute such campaigns. This
                    is no longer the case. We have developed our own in-house
                    capability that allows you to setup and navigate your
                    campaigns in Xandr depending on any weather conditions as
                    reported by{' '}
                    <a href="https://darksky.net" alt="dark sky api">
                      Darksky
                    </a>{' '}
                    API. By connecting to this data source directly on one hand
                    and Xandr API on the other side we are able to setup the
                    activity for you so as it only runs around locations where
                    with specific weather conditions. The settings can be
                    updated as often as once an hour. For more technical details
                    please refer to user{' '}
                    <a
                      href="/assets/files/weatherdocs/WeatherTargeting_Algorithm-UserGuide.pptx"
                      alt="Weather algo user guide"
                      target="_blank"
                    >
                      guide
                    </a>
                    .
                  </p>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>What is required to use Weather Targeting</CardTitle>
                <Card.Text>
                  First step is to specify weather conditions that your activity
                  should be triggered by. You can choose one of the general
                  weather types such as Rain, Windy, Sunny, Cloudy etc or
                  specify the conditions exactly using Temperature, Humidity or
                  many other conditions. For a full list of weather conditions
                  supported please refer to the Darksky{' '}
                  <a href="https://darksky.net/dev/docs" alt="dark sky api">
                    documentation
                  </a>{' '}
                  There are no specific requirements as for Xandr settings to
                  work with our Weather Algorithm. Reach out to{' '}
                  <a
                    className="email"
                    href="mailto:XAXEMEAMarketingScience@xaxis.com"
                  >
                    {' '}
                    XAXEMEAMarketingScience@xaxis.com
                  </a>{' '}
                  to learn more or to test this capability.
                  {/*<StyledLink
                    activeClassName="active"
                    to="/optimisation/viking"
                  >
                    Try it out
                </StyledLink>*/}
                  <StyledLinkLong activeClassName="active" to="/dashboard">
                    Return to Dashboard
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled(Card)`
  & a.email:link {
    color: red;
  }
  a.email:hover {
    color: red;
  }
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 540px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  /* height: 163.33px; */
`;

// const LastPara = styled.p`
//   margin-bottom: 12.8rem;
// `;

const LastPara1 = styled.p`
  margin-bottom: 19.7rem;
`;

// const StyledLink = styled(NavLink)`
//   &:link,
//   &:visited {
//     margin-top: 2rem;
//     display: flex;
//     width: 80px;
//     align-items: center;
//     text-decoration: none;
//     padding: 0.6rem 1.2rem;
//     transition: ${transition.default};
//     color: ${color.grey.dark};
//     border-radius: 4px;
//     background-color: ${color.white};
//     &:hover,
//     &:active,
//     &.${(props) => props.activeClassName} {
//       color: ${color.primary.default};
//       background-color: ${color.grey.bg};
//     }
//   }
// `;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 14.8rem;
    display: flex;
    width: 150px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default WeatherAlgoinfo;
