import React, { useState } from 'react';

import moment from 'moment';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.scss';
import { Formik, Form, useFormikContext } from 'formik';

const DatePickerWithFormik = ({
  isOutsideRange,
  form: { setFieldValue, values, errors, touched },
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const formik = useFormikContext();

  return (
    <DateRangePicker
      startDate={formik.values.startDate}
      startDateId="Start"
      endDate={formik.values.endDate}
      endDateId="End"
      onDatesChange={({ startDate, endDate }) => {
        setFieldValue('startDate', startDate);
        setFieldValue('endDate', endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      isOutsideRange={isOutsideRange}
      numberOfMonths={1}
      displayFormat={() => moment.localeData().longDateFormat('LL')}
    />
  );
};

export default DatePickerWithFormik;
