import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';

import store from 'store/store';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.OAUTH_DOMAIN,
      scope: config.cognito.OAUTH_SCOPE,
      redirectSignIn: config.cognito.OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: config.cognito.OAUTH_REDIRECT_SIGN_OUT,
      responseType: config.cognito.OAUTH_RESPONSE_TYPE,
      options: config.cognito.OAUTH_OPTIONS,
    },
  },
  Analytics: {
    AWSPinpoint: {
      appId: '11cbb7f50dee46b98707258ec84cc2d0',
      region: 'eu-west-1',
    },
  },
  Storage: {
    AWSS3: {
      bucket: config.s3.BUCKET,
      region: config.s3.REGION,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
