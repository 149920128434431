import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';

import explorerImage from '../../assets/img/map.png';
import toolsImage from '../../assets/img/cogwheel.png';
import scoutImage from '../../assets/img/scout.jpg';
//import boaImage from '../../assets/img/boa.jpg';
import vikingImage from '../../assets/img/viking.jpg';
import reportingImage from '../../assets/img/reporting2.jpg';
import xposureImage from '../../assets/img/long-exposure-startrail-photography-lincoln-harrison-12.jpg';
import espressoImage from '../../assets/img/espresso.jpg';
//import orangeSegments from '../../assets/img/orangeSegments.jpg';
import scrabbleImage from '../../assets/img/scrabble.jpg';
import weatherImage from '../../assets/img/weather.jpg';
import autoscanImage from '../../assets/img/autoscan.jpg';
import youtubeImage from '../../assets/img/youtube1.jpg';

const Dashboard = () => {
  const subheader = {
    main: 'Dashboard',
    levels: [
      {
        name: 'Dashboard',
        link: '/dashboard',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <DashboardCard className="darkBlue first">
              <Card.Body>
                <CardTitle>Welcome to TOOLS!</CardTitle>
                <Figure>
                  <CardGraphic src={toolsImage} width="130" alt="Xaxis Tools" />
                </Figure>
                <Card.Text>
                  Xaxis’ portal for accessing tools to help turn your everyday
                  operational nightmares into your dream tasks!
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <DashboardCard className="orange first">
              <Card.Body>
                <CardTitle>CAMPAIGN EXPLORER</CardTitle>
                <Figure>
                  <CardGraphic
                    src={explorerImage}
                    width="150"
                    alt="Campaign Explorer"
                  />
                </Figure>
                <Card.Text>
                  Want to spend less time producing a client report? Want to get
                  off to lunch early? Get a powerpoint presentation emailed to
                  yourself showcasing your campaign’s results.
                  <StyledLink
                    activeClassName="active"
                    to="/reporting/campaign-explorer"
                  >
                    Try it out
                  </StyledLink>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="orange second">
              <CardImg
                variant="top"
                //height="320px"
                //width="320px"
                src={youtubeImage}
              />
              <Card.Body>
                <CardTitle> CAMPAIGN EXPLORER VIDEO</CardTitle>
                <Card.Text>
                  Automated PowerPoint report creation for your YouTube,
                  Integrated Video and Xandr Video campaigns.
                </Card.Text>
                <StyledLinkTopMargin2
                  activeClassName="active"
                  to="/reporting/campaign-explorer-video"
                >
                  Try it out
                </StyledLinkTopMargin2>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="forestgreen second">
              <CardImg variant="top" src={scoutImage} />
              <Card.Body>
                <CardTitle>GOOGLE SEARCH TRENDS ALGORITHM</CardTitle>
                <Card.Text>
                  Want a change from the same old audience based reach
                  targeting? Try dynamic reach targeting based upon Google
                  search trends
                  <StyledLinkMedium
                    activeClassName="active"
                    to="/optimisation/googlesearchalgoinfo"
                  >
                    Find out more
                  </StyledLinkMedium>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="greyMedium second">
              <CardImg variant="top" src={vikingImage} />
              <Card.Body>
                <CardTitle>WEB ANALYTICS OPTIMISATION ALGORITHM</CardTitle>
                <Card.Text>
                  Want advertising that delivers high performing conversions?
                  Test out Boris’ custom algorithm &amp; winner of Xaxis case
                  study battle yourself.
                  <StyledLinkMedium
                    activeClassName="active"
                    to="/optimisation/vikinginfo"
                  >
                    Find out more
                  </StyledLinkMedium>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={scrabbleImage} />
              <Card.Body>
                <CardTitle>CONTEXTUAL TOOLBOX</CardTitle>
                <Card.Text>
                  Find out what content type categories drive performance for
                  your campaign. Activate Xaxis proprietary contextual segments.{' '}
                  <StyledLinkTopMargin2
                    activeClassName="active"
                    to="/contextual-toolbox"
                  >
                    Try it out
                  </StyledLinkTopMargin2>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="espresso second">
              <CardImg variant="top" src={espressoImage} />
              <Card.Body>
                <CardTitle>ESPRESSO AUDIENCE PROFILING</CardTitle>

                <Card.Text>
                  This profiling report provides audience insights to EMEA
                  markets using the new IABv2 segments.
                  <StyledLinkTopMargin2
                    activeClassName="active"
                    to="/reporting/espressoprofiling"
                  >
                    Try it out
                  </StyledLinkTopMargin2>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="lighterBlue second">
              <CardImg variant="top" src={xposureImage} />
              <Card.Body>
                <CardTitle>EXPOSURE TIME</CardTitle>
                <Card.Text>
                  Try our innovative Exposure Time reporting tool for automatic
                  Time In View report generation.
                  <StyledLinkTopMargin
                    activeClassName="active"
                    to="/reporting/xposure"
                  >
                    Try it out
                  </StyledLinkTopMargin>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={autoscanImage} />
              {/*<LogoImg variant="top" src={autoscanLogoImage} />*/}
              <Card.Body>
                <CardTitle>AUTOSCAN</CardTitle>
                <Card.Text>
                  Want to perform tag audits more efficiently?{' '}
                  <a
                    href="//autoscan.ihub.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Autoscan
                  </a>{' '}
                  is what you need. It allows you to scan websites for pixels
                  and tags automatically to ensure they are present.
                </Card.Text>
                <StyledLinkMedium2 to="/campaign/autoscaninfo">
                  Find out more{' '}
                </StyledLinkMedium2>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <DashboardCard className="midBlue second">
              <CardImg variant="top" src={weatherImage} />
              <Card.Body>
                <CardTitle>WEATHER TARGETING (COMING SOON!)</CardTitle>
                <Card.Text>
                  A proprietary solution for geo targeting your campaigns
                  depending on weather conditions and forecasts.
                  <StyledLinkMedium3
                    activeClassName="active"
                    to="/campaign/weatheralgoinfo"
                  >
                    Find out more
                  </StyledLinkMedium3>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12}>
            <CardImgSeparate variant="" src={reportingImage} />
          </Col>
          <Col xl={9} lg={9} md={9} sm={12}>
            <DashboardCard className="darkBlue first">
              {/*<CardImg variant="top" src={reportingImage} />*/}
              <Card.Body>
                <CardTitle>MORE FROM EMEA MARKETING SCIENCE</CardTitle>

                <LastPara2>
                  Want to find out more about how EMEA Marketing Science Team
                  can help you? Visit our SharePoint and check our{' '}
                  <a
                    //className="email"
                    href="https://insidemedia.sharepoint.com/sites/AnalyticalSolutions/SitePages/Advanced-Analytics.aspx"
                    alt="Advanced Analytics"
                    target="_blank"
                  >
                    Advanced Analytics
                  </a>{' '}
                  or{' '}
                  <a
                    //className="email"
                    href="https://insidemedia.sharepoint.com/sites/AnalyticalSolutions/SitePages/CustomActivationSolutions.aspx"
                    alt="Custom Activation Solutions"
                    target="_blank"
                  >
                    Custom Activation Solutions.
                  </a>
                  &nbsp;Alternatively get in touch with us at{' '}
                  <a
                    className="email"
                    href="mailto:XAXEMEAMarketingScience@xaxis.com"
                  >
                    XAXEMEAMarketingScience@xaxis.com
                  </a>
                </LastPara2>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled(Card.Body)`
  //  &.tryOuButton{position:absolute;
  //  bottom:10px;background:red}
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  & a.email:link {
    color: red;
  }
  a.email:hover {
    color: red;
  }
  &.first {
    min-height: 150px;
  }
  &.second {
    /* min-height: 340px; */
    height: 360px;
  }
  &.carousel {
    min-height: 355px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
  &.purple {
    background-color: ${color.primary.purple};
    color: white;
  }
  &.midBlue {
    background-color: ${color.primary.midblue};
    color: white;
  }
  &.forestgreen {
    background-color: ${color.primary.forestgreen};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardGraphic = styled.img`
  backface-visibility: hidden;
  transition: all 0.5s;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  /* height: 163.33px; */
`;

const CardImgSeparate = styled(Card.Img)`
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  height: 150px;
`;

const Figure = styled.figure`
  width: 15rem;
  height: 15rem;
  float: right;
  margin-top: -70px;
  margin-right: -20px;
  right: 10px;
  overflow: hidden;
`;

// const LastPara = styled.p`
//   margin-bottom: 11.4rem;
// `;

// const LastPara1 = styled.p`
//   margin-bottom: 4rem;
// `;

const LastPara2 = styled.p`
  margin-bottom: 2.6rem;
`;

const StyledLink = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 1rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkTopMargin = styled(NavLink)`
  &:link,
  &:visited {
    position: absolute;
    bottom: 10px;
    margin-top: 5.5rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkTopMargin2 = styled(NavLink)`
  &:link,
  &:visited {
    position: absolute;
    bottom: 10px;
    margin-top: 1.5rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkMedium = styled(NavLink)`
  &:link,
  &:visited {
    position: absolute;
    bottom: 10px;
    margin-top: 2rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkMedium2 = styled(NavLink)`
  &:link,
  &:visited {
    position: absolute;
    bottom: 10px;
    margin-top: 1.5rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkMedium3 = styled(NavLink)`
  &:link,
  &:visited {
    position: absolute;
    bottom: 10px;
    margin-top: 1.9rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkTopMarginMedium = styled(NavLink)`
  &:link,
  &:visited {
    //position:absolute;
    //bottom:10px;
    margin-top: 3.5rem;
    display: flex;
    width: 120px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default Dashboard;
