import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from '../../Reporting/CampaignExplorer/seatIds';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';

export class Viking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.0.1',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'inputType',
            initialValue: 'GOOGLE',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Input Type',
              options: [
                { value: 'GOOGLE', label: 'Google Analytics' },
                {
                  value: 'ADOBE',
                  label: 'Adobe Analytics - Coming Soon',
                  isDisabled: true,
                },
              ],
              defaultValue: 0,
            },
          },
          {
            name: 'maxBid',
            initialValue: 2.5,
            type: 'number',
            required: true,
            config: {
              placeholder: 'Max Bid  - default 2.5',
            },
          },
          {
            name: 'outcomeType',
            initialValue: 'APPNEXUS',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Outcome Type',
              options: [
                { value: 'APPNEXUS', label: 'AppNexus' },
                { value: 'GOOGLEDV360', label: 'Googledv360', isDisabled: true },
              ],
            },
          },
          {
            name: 'csvUpload',
            initialValue: '',
            type: 'file',
            required: true,
            config: {
                placeholder: 'Upload CSV file'
            }
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing a seat ID is required.'),
    inputType: Yup.string().required('Choosing an input type is required.'),
    maxBid: Yup.number()
      .typeError('The maximum bid must be a number.')
      .positive('The maximum bid must be positive.'),
    csvUpload: Yup.string().required('Please upload a csv file'),
    outcomeType: Yup.string().required('Select an outcome type'),
  });

  createFormData = (values, email) => {
    return {
      id: values.id,
      seatId: values.seatId.value,
      inputType: values.inputType.value ? values.inputType.value : values.inputType,
      maxBid: values.maxBid.value,
      csvUpload: values.csvUpload.value,
      outcomeType: values.outcomeType.value ? values.outcomeType.value : values.outcomeType,
      //outcomeGoal: values.outcomeGoal ? values.outcomeGoal : null,
      userEmail: email,
    };
  };

  submitHandler = values => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then(response => {
        this.postDataHandler(formData);
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = formData => {
    api
      .post('/viking/createViking', formData)
      .then(response => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            //jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then(data => console.log('signing out', data))
      .catch(err => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Optimisation',
      levels: [
        {
          name: 'Viking',
          link: '/optimisation/viking',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
          <Card className="xtCard">
            <Card.Body>
              <h1>Viking - the Xaxis web analytics tool</h1>
              {this.state.hasSubmitted ? (
                response
              ) : (
                <React.Fragment>
                  <p><i>The Viking tool models and builds a bid modifying algorithm based on 1st party site analytics data from platforms such as Google Analytics. By analyzing different variables 
                    that are statistically significant, and their effect on the probability of an outcome occuring, a decision tree is automatically produced which is easily implemented through Copilot's 
                    Custom Tree. The main variables used are time of day, day of the week, device, and/or region.</i></p>
                  <p>
                    Please select an input type and upload a valid csv file - this will start the process of returning a new csv file.
                  </p>
                  <Form
                    setSubmitted={this.setSubmitted}
                    controls={this.state.form}
                  />
                </React.Fragment>
              )}
              <p>
                You can download the following PDF document if you need an
                explanation of Viking's features and
                functionality:
              </p>
              <ul>
                <li>
                  <a
                    href="/assets/files/"
                    alt="Viking User Guide"
                    target="_blank"
                  >
                    Viking User Guide
                  </a> Coming soon
                  <p>
                    Provides a summary of how to use the Viking tool.
                  </p>
                </li>
              </ul>
              <p><a href="https://teams.microsoft.com/l/team/19%3aab5adc1920d44ba793c7729a1a12bd49%40thread.skype/conversations?groupId=9cc97fd6-82b0-49fc-bff4-eda16e1c085a&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3" alt="Link to Teams">Viking tool Teams site</a></p>
              <p>Product Champion: Boris Olij</p>
              <p>Email address: boris.olij@groupm.com</p>
            </Card.Body>
          </Card>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

export default connect(mapStateToProps)(Viking);
