import React from 'react';

import { Card } from 'react-bootstrap';

import classes from './Notifications.module.scss';
import goldBg from 'assets/img/head_bg_sm_xaxis_gold.jpg';

const Notifications = () => {

    return (
        <Card className={classes.Notifications}>
            <Card.Img className={classes.Background} variant="top" src={goldBg} />
            <Card.Body className={classes.Body}>
                <Card.Title className={classes.Title}>
                    Notifications are currently disabled.
                </Card.Title>
            </Card.Body>
        </Card>
    )
};

export default Notifications;