import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';
import moment from 'moment';
import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from './seatIdsYoutubs';
import youtubeImage from '../../../assets/img/youtube1.jpg';
import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';
import vcrseats from 'components/Reporting/CampaignExplorer/seatIds';

export class CompaignExplorerYoutube extends React.Component {
  constructor(props) {
    super(props);
    let seats = [];
    if (this.props.responseData !== undefined) {
      for (var i = 0; i <= this.props.responseData.length - 1; i++) {
        if (this.props.responseData[i].Tool === 'CE YouTube') {
          var c = {};
          c.value = this.props.responseData[i].SeatID;
          c.label = this.props.responseData[i].SeatName;
          if (this.props.responseData[i].IsActive === 'Yes') {
            c.isDisabled = false;
          } else {
            c.isDisabled = true;
          }
          seats.push(c);
        }
      }
    }
    this.state = {
      version: '0.1.2',
      form: {
        fields: [
          {
            name: 'dsp',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'DSP',
              options: [
                {
                  value: 'DV360',
                  label: 'DV360',
                },
                {
                  value: 'Xandr',
                  label: 'Xandr',
                },
              ],
            },
          },

          {
            name: 'campaignType',
            initialValue: '',
            type: 'select',
            required: true,

            config: {
              placeholder: 'Campaign Type',
              options: [
                {
                  value: 'YouTube',
                  label: 'YouTube',
                },
                {
                  value: 'IntegratedVideo',
                  label: 'Integrated Video',
                },
              ],
            },
          },
          {
            name: 'VcrCampaignType',
            initialValue: '',
            type: 'select',
            required: true,
            isDisabled: true,
            config: {
              placeholder: 'Campaign Type',
              options: [
                {
                  value: 'VCR',
                  label: 'VCR',
                },
              ],
            },
          },

          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'vcrseatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: vcrseats,
            },
          },
          {
            name: 'insertionOrderId',
            initialValue: '',
            type: 'number',
            required: true,
            config: {
              placeholder: 'Insertion Order ID',
              insertionOrderId: Yup.number().required(),
              explanation:
                'Please insert a single insertion order. Only numeric characters are allowed in this field.',
            },
          },
          {
            name: 'campaignId',
            initialValue: '',
            type: 'number',
            required: true,
            config: {
              placeholder: 'Campaign ID',
              campaignId: Yup.number().required(),
              explanation:
                'Please insert a single Campaign ID. Only numeric characters are allowed in this field.',
            },
          },
          {
            name: 'dateRange',
            initialValue: null,
            type: 'date',
            required: true,
            config: {
              dateRange: true,
              startDate: Yup.date().nullable().typeError('Invalid Date'),
              endDate: null,
              placeholder: 'Date Range',
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    // seatId: Yup.string().required('Choosing an Seat ID is required.'),
    // campaignType: Yup.string().required(
    //   'Choosing an outcome type is required.'
    // ),
    // insertionOrderId: Yup.number().required(
    //   'The insertion order ID must be a number.'
    // ),
    // campaignId: Yup.number().required(
    //   'The insertion order ID must be a number.'
    // ),
    startDate: Yup.date().required('A start date is required.'),
  });

  createFormData = (values, email) => {
    var date = new moment().format('YYYY-MM-DD hh:mm:ss');
    if (
      values.campaignType.value === 'YouTube' ||
      values.dsp.value === 'Xandr'
    ) {
      var insertionOrderId = values.insertionOrderId;
      var CampaignId = null;
    } else {
      var insertionOrderId = null;
      var CampaignId = values.campaignId;
    }
    return {
      jobId: values.jobId,
      RunDate: String(date),
      seatId:
        values.dsp.value === 'Xandr'
          ? values.vcrseatId.value
          : values.seatId.value,
      // seatId: values.seatId.value,
      insertionOrderId: insertionOrderId,
      CampaignID: CampaignId,
      campaignType:
        values.dsp.value === 'Xandr'
          ? values.VcrCampaignType.value
          : values.campaignType.value,
      startDate: values.startDate.toISOString().split('T')[0],
      endDate: values.endDate.toISOString().split('T')[0],
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then((response) => {
        if (
          formData.campaignType == 'YouTube' &&
          formData.insertionOrderId == ''
        ) {
          return false;
          this.setState({ submitting: false });
        } else if (
          formData.campaignType == 'IntegratedVideo' &&
          formData.CampaignID == ''
        ) {
          return false;
          this.setState({ submitting: false });
        } else if (formData.dsp == 'Xandr' && formData.insertionOrderId == '') {
          return false;
        } else {
          this.setState({ submitting: true });
          this.setState({ hasSubmitted: true });
          this.postDataHandler(formData);
        }
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = (formData) => {
    api
      .post('/campaignExplorerYoutube/putFormData', formData)
      .then((response) => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Campaign Explorer Video',
          link: '/reporting/campaign-explorer-video',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />

        <StyledContainer>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xl={3} lg={3} md={3} sm={12}>
                <DashboardCard className="orange second">
                  <CardImg variant="top" src={youtubeImage} />
                  <Card.Body>
                    <CardTitle>
                      <b> CAMPAIGN EXPLORER VIDEO</b>
                    </CardTitle>
                    <Card.Text>
                      Campaign Explorer Video allows you to automatically
                      generate pptx reports for your YouTube, Integrated Video
                      and Xandr Video campaigns. This is available for all
                      standard YouTube campaigns and display video.
                    </Card.Text>
                  </Card.Body>
                </DashboardCard>
              </Col>
              <Col xl={9} lg={9} md={9} sm={12}>
                <Card className="xtCard">
                  <Card.Body>
                    <h1>
                      <b> Campaign Explorer Video</b>
                    </h1>
                    {this.state.hasSubmitted ? (
                      response
                    ) : (
                      <React.Fragment>
                        <p>
                          Please fill out the form - this will start the process
                          to generate the PowerPoint Template.
                        </p>
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      </React.Fragment>
                    )}
                    <p>Please see below for the intro deck and User Guide:</p>
                    <p className="m-0">
                      <a
                        href="/assets/files/CampaignExplorerYoutubeIntroDeck.pdf"
                        alt="Campaign Explorer YouTube Intro Deck"
                        target="_blank"
                      >
                        Campaign Explorer YouTube Intro Deck
                      </a>{' '}
                      {'\n'}
                    </p>
                    <p>
                      Provides a summary of how to export a report from Campaign
                      eXplorer and how to interpret the output.
                    </p>
                    <p className="m-0">
                      <a
                        href="/assets/files/CampaignExplorerYoutubeUserGuide.pdf"
                        alt="Campaign Explorer User Guide"
                        target="_blank"
                      >
                        Campaign Explorer User Guide
                      </a>{' '}
                      {'\n'}
                    </p>
                    <p>
                      Provides a summary of the rationale and motivation for
                      building Campaign eXplorer. Example report and some
                      potential upcoming developments also included.
                    </p>
                    <p>
                      <a
                        href="https://teams.microsoft.com/l/channel/19%3ad62677fada06410b80440244999134f9%40thread.skype/EMEA-Campaign-eXplorer?groupId=d55d7eb5-5db7-4179-81d9-f886b7bea4a6&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3"
                        alt="Espresso Sample Report"
                        target="_blank"
                      >
                        Campaign Explorer Teams Site
                      </a>{' '}
                      {'\n'}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
    responseData: state.auth.Data,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

// const blueText = styled.p`
//   color: rgb(40, 173, 226);
// `;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

// const CardGraphic = styled.img`
//   backface-visibility: hidden;
//   transition: all 0.5s;
// `;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(CompaignExplorerYoutube);
