import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';

import vikingImage from '../../../assets/img/viking.jpg';

const Vikinginfo = () => {
  const subheader = {
    main: 'Optimisation',
    levels: [
      {
        name: 'Web Analytics Optimisation Algorithm ',
        link: '/optimisation/vikinginfo',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={vikingImage} />
              <Card.Body>
                <CardTitle>Web Analytics Optimisation Algorithm </CardTitle>
                <Card.Text>
                  An optimization algorithm based on Clients’ site analytics
                  first party data. Test it by contacting
                </Card.Text>
                <LastPara1>XAXEMEAMarketingScience@xaxis.com</LastPara1>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>
                  How the Web Analytics Optimisation Algorithm Works
                </CardTitle>
                <Card.Text>
                  Optimizing for a KPI can be challenging if the available data
                  is limited to just a few days or a few weeks, as it is the
                  case for most algorithms based on DSP data. That’s why the Web
                  Analytics Optimisation Algorithm was born in Norway. The idea
                  is to capture outcome success patterns by looking at data from
                  a longer history of site visits. This data typically comes
                  from site analytics tools, such as Google or Adobe Analytics.
                  Our algorithm identifies patterns and turns them into bidding
                  strategies that create unique bids per region/province, day,
                  hour and optionally device.
                </Card.Text>
                <LastPara>
                  As a result a csv file is produced that can be applied via
                  Copilot to build a custom Custom Tree.
                </LastPara>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>
                  What is required to use the Web Analytics Optimisation
                  Algorithm
                </CardTitle>
                <Card.Text>
                  To build your algorithm you will need a standard report from
                  Google Analytics that aggregates your outcome (for example
                  conversions) by hour of day, day of week, region or / a
                  device.
                  <a
                    href="/assets/files/Sample_GA_report.csv"
                    alt="Sample Google Analytics Report"
                    target="_blank"
                  >
                    {' '}
                    Here{' '}
                  </a>
                  you can find an example of such a report and{' '}
                  <a
                    href="/assets/files/DownloadVikingDatawithGoogleAnalytics.docx"
                    alt="Download Viking Data with Google Analytics"
                    target="_blank"
                  >
                    here
                  </a>{' '}
                  is a step-by-step guide on how to pull such a report from GA.
                </Card.Text>
                <CardTitle>Case study</CardTitle>
                <Card.Text>
                  The Web Analytics Optimisation Algorithm applied for Ford in
                  the Netherlands and Norway is one of the 2019 Global Case
                  Study Battle winners, as well as other awards. See the case
                  study{' '}
                  <a
                    href="/assets/files/CaseStudy_Xaxis_Conversions_Ford_Europe.pdf"
                    alt="Viking algo case study"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </Card.Text>
                <CardTitle>Test it NOW!</CardTitle>
                If you feel that could be a good solution for your client get in
                touch with{' '}
                <a
                  className="email"
                  href="mailto:XAXEMEAMarketingScience@xaxis.com"
                >
                  XAXEMEAMarketingScience@xaxis.com
                </a>
                <p>We'll build the algorithum for you!</p>
                {/*<StyledLink
                    activeClassName="active"
                    to="/optimisation/viking"
                  >
                    Try it out
                </StyledLink>*/}
                <StyledLinkLong activeClassName="active" to="/dashboard">
                  Return to Dashboard
                </StyledLinkLong>
                {/*</Card.Text>*/}
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled(Card)`
  & a.email:link {
    color: red;
  }
  a.email:hover {
    color: red;
  }
`;
const DashboardCard = styled(Card)`
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 527px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  /* height: 163.33px; */
`;

const LastPara = styled.p`
  margin-bottom: 12.8rem;
`;

const LastPara1 = styled.p`
  margin-bottom: 18.6rem;
`;

// const StyledLink = styled(NavLink)`
//   &:link,
//   &:visited {
//     margin-top: 2rem;
//     display: flex;
//     width: 80px;
//     align-items: center;
//     text-decoration: none;
//     padding: 0.6rem 1.2rem;
//     transition: ${transition.default};
//     color: ${color.grey.dark};
//     border-radius: 4px;
//     background-color: ${color.white};
//     &:hover,
//     &:active,
//     &.${(props) => props.activeClassName} {
//       color: ${color.primary.default};
//       background-color: ${color.grey.bg};
//     }
//   }
// `;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 2rem;
    display: flex;
    width: 150px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default Vikinginfo;
