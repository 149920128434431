import React from 'react';
import classes from './Logo.module.scss';
import LogoColor from 'assets/img/logo-xaxis-color.png';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <div className={classes.Logo}>
      <NavLink to="/" className={classes.LogoLink}>
        <img className={classes.LogoLogo} src={LogoColor} alt="Xaxis Tools" />
      </NavLink>
    </div>
  );
};

export default Logo;
