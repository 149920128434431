let clientId = '5em2cep67id7b2n78eg2a20710';
let redirectDomain = 'https://tools.xaxis.com/';
const idp = 'WPPOKTA';
let baseurl = 'https://api.xaxis.technology';

// Needs to be ignored for code coverage - tests can only run with one env
/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  clientId = '34ogo49rj3286fq1kp7ud4q34a';
  redirectDomain = 'http://localhost:3000/';
  baseurl = 'https://dev-api.xaxis.technology';
}

// Needs to be ignored for code coverage - tests can only run with one env
/* istanbul ignore next */
if (process.env.REACT_APP_BUILD_ENV === 'livetest') {
  clientId = '5hft4vpk5p1c70lp3e7jmo6kdh';
  redirectDomain = 'https://tools-test.xaxis.com/';
  baseurl = 'https://staging-api.xaxis.technology';
}

export default {
  version: '1.0',
  api: {
    BASEURL: baseurl,
  },
  s3: {
    REGION: 'eu-west-1',
    BUCKET: 'tools-xaxis',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_tuGtZb0Cg',
    APP_CLIENT_ID: clientId,
    IDENTITY_POOL_ID: '',
    OAUTH_DOMAIN: 'toolsxaxis.auth.eu-west-1.amazoncognito.com',
    OAUTH_SCOPE: [
      'email',
      'profile',
      'aws.cognito.signin.user.admin',
      'openid',
    ],
    OAUTH_REDIRECT_SIGN_IN: redirectDomain + 'implicit/callback',
    OAUTH_REDIRECT_SIGN_OUT: redirectDomain,
    OAUTH_RESPONSE_TYPE: 'code',
    OAUTH_OPTIONS: {
      AdvancedSecurityDataCollectionFlag: false,
    },
  },
  auth: {
    REDIRECT_LINK:
      'https://toolsxaxis.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=' +
      idp +
      '&redirect_uri=' +
      redirectDomain +
      'implicit/callback&response_type=code&client_id=' +
      clientId +
      '&scope=aws.cognito.signin.user.admin%20openid%20email',
  },
};
