import React from 'react';
import {
  Circle,
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  Circle5,
  Circle6,
  Circle7,
  Circle8,
  Circle9,
  Circle10,
  Circle11,
  Circle12,
} from './Spinner.style';

const Spinner = () => (
  <Circle>
    <Circle1 />
    <Circle2 />
    <Circle3 />
    <Circle4 />
    <Circle5 />
    <Circle6 />
    <Circle7 />
    <Circle8 />
    <Circle9 />
    <Circle10 />
    <Circle11 />
    <Circle12 />
  </Circle>
);

export default Spinner;
