import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Hub } from 'aws-amplify';

import * as actions from './store/actions';
import Layout from './components/Layout/Layout';
import AuthPage from './components/Auth/Auth';

export class App extends React.Component {
  constructor(props) {
    super(props);
    // TODO: figure out how to test Amplify Hub listen
    // TODO: switch to spinnner while listening
    /* istanbul ignore next */
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          this.props.history.push('/dashboard');
          this.props.onAuthCheck();
          break;
        case 'signIn_failure':
          console.log('the user failed to sign in');
          console.log('the error is', data.payload.data);
          break;
        case /.*/:
          console.log('Listening for all messages: ', data.payload.data);
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    this.props.onAuthCheck();
  }

  render() {
    return this.props.isAuthenticated ? <Layout /> : <AuthPage />;
  }
}

// actions are tested in action file
/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

// actions are tested in action file
/* istanbul ignore next */

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheck: () => dispatch(actions.authCheck()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
