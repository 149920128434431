import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { color, transition } from 'utils/style/variables';

const Subheader = props => {
  const levels = props.config.levels.map((level, i) => {
    return (
      <React.Fragment key={level.name}>
        <StyledLink to={level.link}>{level.name}</StyledLink>
        {i < props.config.levels.length - 1 ? <SeparatorDot /> : null}
      </React.Fragment>
    );
  });

  return (
    <Row>
      <Col>
        <StyledSubheader>
          <Title>{props.config.main}</Title>
          <Separator />
          <Breadcrumbs>{levels}</Breadcrumbs>
        </StyledSubheader>
      </Col>
    </Row>
  );
};

const StyledSubheader = styled.div`
  display: flex;
  margin: 2.5rem 0;
  padding: 0;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0 1.5rem 0 0;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
  color: ${color.black};
`;

const Separator = styled.span`
  display: inline-block;
  width: 1px;
  border-radius: 2px;
  background: ${color.primary.default};
  margin: 0.3rem 1.5rem 0.3rem 0;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: ${color.grey.medium} !important;
  font-size: 1.3rem;
  font-weight: 500;
  transition: ${transition.default};
  text-decoration: none;

  &:hover {
    color: ${color.primary.default} !important;
    text-decoration: none;
  }
`;

const SeparatorDot = styled.span`
  display: inline-block;
  margin: 0 0.5rem;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: ' ';
  background: ${color.primary.default};
`;

export default Subheader;
