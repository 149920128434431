import styled, { keyframes } from 'styled-components';
import { color } from 'utils/style/variables';

const skCircleBounceDelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const Circle = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

const Child = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${color.primary.default};
    border-radius: 100%;
    -webkit-animation: ${skCircleBounceDelay} 1.2s infinite ease-in-out both;
    animation: ${skCircleBounceDelay} 1.2s infinite ease-in-out both;
  }
`;

const Circle1 = styled(Child)`
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  &:before {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
  }
`;
const Circle2 = styled(Child)`
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  &:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
`;
const Circle3 = styled(Child)`
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  &:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`;
const Circle4 = styled(Child)`
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  &:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
`;
const Circle5 = styled(Child)`
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
  &:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
`;
const Circle6 = styled(Child)`
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
  &:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
`;
const Circle7 = styled(Child)`
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  &:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
`;
const Circle8 = styled(Child)`
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  &:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
`;
const Circle9 = styled(Child)`
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
  &:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
`;
const Circle10 = styled(Child)`
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  &:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
`;
const Circle11 = styled(Child)`
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  &:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
`;
const Circle12 = styled(Child)`
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  &:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
`;

export {
  Circle,
  Circle1,
  Circle2,
  Circle3,
  Circle4,
  Circle5,
  Circle6,
  Circle7,
  Circle8,
  Circle9,
  Circle10,
  Circle11,
  Circle12,
};
