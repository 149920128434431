import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color } from 'utils/style/variables';

import Form from './Form/Form';
import crystalBall from '../../../assets/img/Crystal-Ball.jpg';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';

const data = [{"segment": "news", "unique_users": "23", "total_impressions": "201", "total_spend": "1000"},
    {"segment": "lifestyle", "unique_users": "45", "total_impressions": "327", "total_spend": "1500"}]

export class ForecastingOptions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: data,  
      version: '0.1.2',
      form: {
        fields: [
            {
                name: 'segmentSelector',
                initialValue: [],
                type: 'tags',
                required: false,
                config: {
                  placeholder: 'Segment Selector',
                  
                },
            },
          {
            name: 'campaignDuration',
            initialValue: '',
            type: 'select',
            config: {
              placeholder: 'Campaign Duration',
              options: [
                { value: '2', label: 'Two weeks' },
                { value: '4', label: 'Four weeks' },
              ],
            },
          },
          {
            name: 'exposureTime',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
                placeholder: 'Exposure Time',
                options: [
                  { value: '5', label: 'Five seconds' },
                  { value: '10', label: 'Ten seconds' },
                ],
            },
          },
          
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an advertiser ID is required.'),
    analysisType: Yup.string().required('Select an analysis type'),
    //segmentId: Yup.number()
      //.typeError('The insertion order ID must be a number.')
      //.positive('The insertion order ID must be positive.')
      //.integer('The insertion order ID must be an integer.')
      //.required('Adding an insertion order ID is required.'),
    segmentId: Yup.array().of(Yup.number().positive().required('At least one segment Id is required')),
    //startDate: Yup.date().required('A start date is required.'),
    //endDate: Yup.date().required('An end date is required.'),
  });

  createFormData = (values, email) => {
    return {
      jobId: values.jobId,
      dateTime: String(Date.now()),
      seatId: values.seatId.value,
      //segmentId: values.segmentId,
      analysisType: values.analysisType.value,
      //startDate: values.startDate.toISOString().split('T')[0],
      //endDate: values.endDate.toISOString().split('T')[0],
      userEmail: email,
    };
  };

  submitHandler = values => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then(response => {
        this.postDataHandler(formData);
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = formData => {
    api
      //.post('/xposure/createXposure', formData)
      //.post('/xposuretest/putFormData', formData)
      .post('/espressoprofiling/putFormData', formData)
      .then(response => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then(data => console.log('signing out', data))
      .catch(err => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Foot',
          link: '/reporting/forecastingoutcome',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <row>
        <StyledContainer>
        <Col xl={3} lg={3} md={3} sm={12} className='cardandForm'>
            <DashboardCard className="lighterBlue second">
              <CardImg variant="top" src={crystalBall} />
              <Card.Body>
                <CardTitle>FOOT (Coming Soon!)</CardTitle>
                <Card.Text>
                This report allows the end user to better understand exposure times. 
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={9} lg={9} md={9} sm={12} className='cardandForm2'>
          <Card className="xtCard">
            <Card.Body>
              <h1>Foot</h1>
              {this.state.hasSubmitted ? (
                response
              ) : (
                <React.Fragment>
                  <p>
                    Please fill out the form.
                  </p>
                  <Form
                    setSubmitted={this.setSubmitted}
                    controls={this.state.form}
                  />
                </React.Fragment>
              )}
              <p>Product Champion: Maciej</p>
              {this.state.data.map(item => 
              <div>  
                <p>Segment: {item.segment}</p>  
                <p>Estimated unique reach: {item.unique_users}</p>
              <p>Estimated number of impressdions: {item.total_impressions}</p>
              <p>Estimated cost (without optimisation): {item.total_spend}</p>
              </div>
                )}
              
            </Card.Body>
          </Card>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
        </row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: black;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(ForecastingOptions);

