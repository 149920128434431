import styled from 'styled-components';
import { darken, mix } from 'polished';
import { color, font } from 'utils/style/variables';
import { yiq } from 'utils/style/functions';

const StyledButton = styled.button`
  display: inline-block;
  background-color: transparent;
  font-family: ${font.gotham};
  font-size: ${font.size};
  font-weight: 400;
  padding: 0.575rem 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* original bootstrap values */
  /* padding: 0.375rem 0.75rem; */
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: inset 0 1px 0 rgba(${color.white}, 0.15),
      0 1px 1px rgba(${color.black}, 0.075);
  }

  &.disabled {
    opacity: 0.65;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    @include box-shadow(inset 0 3px 5px rgba($color-black, 0.125));

    &:focus {
      @include box-shadow(
        0 0 0 0.2rem rgba($color-primary, 0.25),
        inset 0 3px 5px rgba($color-black, 0.125)
      );
    }
  }
`;

const BootstrapButton = styled(StyledButton).attrs(props => ({
  background: props.primary
    ? color.primary.default
    : props.outline
    ? color.white
    : 'transparent',
  border: color.primary.default,
}))`
  color: ${props =>
    props.primary
      ? yiq(props.background)
      : props.outline
      ? color.primary.default
      : color.grey.c900};

  border-color: ${props => props.border};
  background-color: ${props => props.background};
  &:hover {
    color: ${props =>
      props.primary
        ? yiq(props.background)
        : props.outline
        ? yiq(color.primary.default)
        : color.grey.c900};
    background-color: ${props =>
      props.primary
        ? darken('0.075', props.background)
        : props.outline
        ? color.primary.default
        : color.white};
    border-color: ${props => props.border};
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem
      rgba(
        ${props =>
          props.primary
            ? mix('0.15', yiq(props.background), props.border)
            : color.primary.default},
        0.5
      );
  }
  &:disabled {
    color: ${props => yiq(props.background)};
    background-color: ${props => props.background};
    border-color: ${props => props.border};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${props => yiq(color.primary.default)};
    background-color: ${color.primary.default};
    border-color: ${color.primary.default};
    &:focus {
      box-shadow: 0 0 0 0.2rem
        rgba(
          ${props =>
            props.primary
              ? mix('0.15', yiq(props.background), props.border)
              : color.primary.default},
          0.5
        );
    }
  }
`;

export default BootstrapButton;
