import React from 'react';
import Select from 'react-select';

const SelectWrapper = ({
  form: { setFieldValue, setFieldTouched, errors, touched },
  field,
  ...props
}) => {
  const styles = {
    container: provided => ({ ...provided }),
    control: provided => ({
      ...provided,
      transition: 'all 0.3s',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      '&:hover': {
        border: '1px solid #adb5bd',
      },
    }),
  };

  const changeHandler = event => {
    setFieldValue(field.name, event);
    if (field.name === 'seatId') {
      setFieldValue('currency', event.currencySign);
    }
    if (field.name === 'outcomeType') {
      setFieldValue('outcomeGoal', '');
    }
  };

  return (
    <Select
      styles={styles}
      // check if defaultValue is defined, otherwise don't have one
      defaultValue={
        field.config.defaultValue
          ? field.config.options[field.config.defaultValue]
          : null
      }
      name={field.name}
      onChange={changeHandler}
      onBlur={e => setFieldTouched(field.name, e)}
      error={errors.seatId}
      touched={touched.seatId}
      options={field.config.options}
    />
  );
};

export default SelectWrapper;
