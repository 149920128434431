export default [
  {
    "value": 1848,
    "label": "Xaxis - Austria (1848)",
    "country_code": "at",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1847,
    "label": "Xaxis - Belgium (1847)",
    "country_code": "be",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1852,
    "label": "Xaxis - Czech Republic (1852)",
    "country_code": "cz",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "K\u010D"
  },
  {
    "value": 1787,
    "label": "Xaxis - Denmark (1787)",
    "country_code": "dk",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "kr."
  },
  {
    "value": 2728,
    "label": "Xaxis - Finland (2728)",
    "country_code": "fi",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 2574,
    "label": "Xaxis - France (2574)",
    "country_code": "fr",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1786,
    "label": "Xaxis - Germany (1786)",
    "country_code": "de",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 3994,
    "label": "Xaxis - Hungary (3994)",
    "country_code": "hu",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "Ft"
  },
  {
    "value": 1785,
    "label": "Xaxis - Italy (1785)",
    "country_code": "it",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 2847,
    "label": "Xaxis - MENA (2847)",
    "country_code": "ae",
    "region": "MENA",
    "organisation": "Xaxis",
    "currencySign": "$"
  },
  {
    "value": 1849,
    "label": "Xaxis - Norway (1849)",
    "country_code": "no",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "kr"
  },
  {
    "value": 1788,
    "label": "Xaxis - Netherlands (1788)",
    "country_code": "nl",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 3090,
    "label": "Xaxis Performance - Netherlands (3090)",
    "country_code": "nl",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1845,
    "label": "Xaxis - Pan Regional (1845)",
    "country_code": "eu",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1789,
    "label": "Xaxis - Poland (1789)",
    "country_code": "pl",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "Z\u0142"
  },
  {
    "value": 1790,
    "label": "Xaxis - Portugal (1790)",
    "country_code": "pt",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 2693,
    "label": "Xaxis - Romania (2693)",
    "country_code": "ro",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "lei"
  },
  {
    "value": 1853,
    "label": "Xaxis - Russia (1853)",
    "country_code": "ru",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u0440"
  },
  {
    "value": 2848,
    "label": "Xaxis - EMEA - Russia (2848)",
    "country_code": "ru",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u0440"
  },
  {
    "value": 3286,
    "label": "Xaxis - Slovakia (3286)",
    "country_code": "sk",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1846,
    "label": "Xaxis - Spain (1846)",
    "country_code": "es",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 1850,
    "label": "Xaxis - Sweden (1850)",
    "country_code": "se",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "kr"
  },
  {
    "value": 1851,
    "label": "Xaxis - Switzerland (1851)",
    "country_code": "ch",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "SFr"
  },
  {
    "value": 1791,
    "label": "Xaxis - Turkey (1791)",
    "country_code": "tr",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20A4"
  },
  {
    "value": 364,
    "label": "Xaxis - UK (364)",
    "country_code": "gb",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u00A3"
  },
  {
    "value": 8358,
    "label": "Headlight - Belgium (8358)",
    "country_code": "be",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8192,
    "label": "Headlight Czech Republic (8192)",
    "country_code": "cz",
    "region": "EMEA",
    "organisation": "Headlight",
    "currencySign": "K\u010D"
  },
  {
    "value": 8286,
    "label": "Headlight - Finland (8286)",
    "country_code": "fi",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 7945,
    "label": "Headlight - Germany (7945)",
    "country_code": "de",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8548,
    "label": "Headlight - Hungary (8548)",
    "country_code": "hu",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "Ft"
  },
  {
    "value": 7643,
    "label": "Headlight - Italy (7643)",
    "country_code": "it",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8232,
    "label": "Headlight - Netherlands (8232)",
    "country_code": "nl",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8284,
    "label": "HeadLight - Norway (8284)",
    "country_code": "no",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "kr"
  },
  {
    "value": 7942,
    "label": "Headlight - Pan-Regional (7942)",
    "country_code": "de",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8549,
    "label": "Headlight - Romania (8549)",
    "country_code": "ro",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "lei"
  },
  {
    "value": 7661,
    "label": "Headlight - Spain (7661)",
    "country_code": "es",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u20AC"
  },
  {
    "value": 8295,
    "label": "Headlight - Sweden (8295)",
    "country_code": "se",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "kr"
  },
  {
    "value": 8455,
    "label": "Headlight - Switzerland (8455)",
    "country_code": "ch",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "SFr"
  },
  {
    "value": 7490,
    "label": "Headlight - UK (7490)",
    "country_code": "gb",
    "region": "EMEA",
    "organisation": "Xaxis",
    "currencySign": "\u00A3"
  },
  {
    "value": 3591,
    "label": "Light Reaction - Austria (3591)",
    "country_code": "at",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 2368,
    "label": "Light Reaction - Belgium (2368)",
    "country_code": "be",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 3483,
    "label": "Light Reaction - Czech Republic (3483)",
    "country_code": "cz",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "K\u010D"
  },
  {
    "value": 7337,
    "label": "Light Reaction - Germany (7337)",
    "country_code": "de",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 4008,
    "label": "Light Reaction - Hungary (4008)",
    "country_code": "hu",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "Ft"
  },
  {
    "value": 3141,
    "label": "Light Reaction - Italy (3141)",
    "country_code": "it",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 3423,
    "label": "Light Reaction - MENA (3423)",
    "country_code": "ae",
    "region": "MENA",
    "organisation": "Light Reaction",
    "currencySign": "$"
  },
  {
    "value": 2588,
    "label": "Light Reaction - Netherlands (2588)",
    "country_code": "nl",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 7338,
    "label": "Light Reaction - Poland (7338)",
    "country_code": "pl",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "Z\u0142"
  },
  {
    "value": 3588,
    "label": "Light Reaction - Romania (3588)",
    "country_code": "ro",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "lei"
  },
  {
    "value": 3748,
    "label": "Light Reaction - Slovakia (3748)",
    "country_code": "sk",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 6840,
    "label": "Light Reaction - Spain (6840)",
    "country_code": "es",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20AC"
  },
  {
    "value": 3613,
    "label": "Light Reaction - Sweden (3613)",
    "country_code": "se",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "kr"
  },
  {
    "value": 3528,
    "label": "Light Reaction - Switzerland (3528)",
    "country_code": "ch",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "SFr"
  },
  {
    "value": 3572,
    "label": "Light Reaction - Turkey (3572)",
    "country_code": "tr",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u20A4"
  },
  {
    "value": 2686,
    "label": "Light Reaction - UAE (2686)",
    "country_code": "ae",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "$"
  },
  {
    "value": 2048,
    "label": "Light Reaction - UK (2048)",
    "country_code": "gb",
    "region": "EMEA",
    "organisation": "Light Reaction",
    "currencySign": "\u00A3"
  }
];
