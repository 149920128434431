// const colors = {
//   rgb: [
//     'rgb(61,64,17)',
//     'rgb(130, 143, 38)',
//     'rgb(178, 194, 53)',
//     'rgb(194,204,117)',
//     'rgb(219,229,132)',
//     'rgb(36,51,79)',
//     'rgb(48,66,105)',
//     'rgb(54,74,117)',
//     'rgb(84,115,181)',
//     'rgb(112,153,245)',
//     'rgb(21,77,99)',
//     'rgb(46,84,99)',
//     'rgb(44,135,176)',
//     'rgb(59,174,227)',
//     'rgb(110,196,232)',
//     'rgb(114,46,0)',
//     'rgb(178,71,0)',
//     'rgb(217,87,0)',
//     'rgb(243,97,0)',
//     'rgb(246,102,3)',
//     'rgb(248,168,12)',
//     'rgb(220,125,11)',
//     'rgb(243,97,0)',
//     'rgb(219,64,9)',
//     'rgb(245,38,13)',
//   ],
//   hex: {
//     green: ['#3D4011', '#828F26', '#B2C235', '#C2CC75', '#DBE584'],
//     blue1: ['#24334F', '#304269', '#364A75', '#5473B5', '#7099F5'],
//     blue2: ['#154D63', '#2E5463', '#2C87B0', '#3BAEE3', '#6EC4E8'],
//     orange: ['#722E00', '#B24700', '#D95700', '#F36100', '#F66603'],
//     orangeAlt: ['#F8A80C', '#DC7D0B', '#F36100', '#DB4009', '#F5260D'],
//   },
// };

export default {
  pointBackgroundColor: '#fff',
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  fill: false,
};
