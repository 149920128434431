import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';
import boaImage from '../../../assets/img/boa.jpg';

const Boainfo = () => {
  const subheader = {
    //main: 'Optimisation',
    levels: [
      {
        //name: 'BOA Information',
        //link: '/optimisation/Boainfo',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          {/*<Col xl={12} lg={12} md={12} sm={12}>
            <DashboardCard className="green first">
              <Card.Body>
                <CardTitle>BOA (COMING SOON)</CardTitle>
                <Figure>
                  {/*<CardGraphic src={toolsImage} width="130" alt="Xaxis Tools" />*/}
          {/*} </Figure>
                <Card.Text>
                Tired of trying to optimise budgets across line items?
                  Introducing BOA - automated multi-line-item budget
                  optimisation, to maximise outcomes.
                  <StyledLinkLong
                    activeClassName="active"
                    to="/dashboard"
                  >
                    Return to Dashboard
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
      </Col> */}

          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={boaImage} />
              <Card.Body>
                <CardTitle>All about BOA</CardTitle>
                <Card.Text>
                  <p>
                    BOA is Xaxis' Budget Optimal Optimization algorithm. It
                    automatically allocates budget across multiple line items
                    (within an Insertion Order) in the most optimal way to
                    deliver the best overall outcome performance. This
                    alleviates the need to guess how much budget to allocate to
                    different line items throughout the life of a campaign.
                  </p>
                  <LastPara>
                    Take a look at the{' '}
                    <a
                      href="/assets/files/BOA_User_Guide_v1_0.pdf"
                      alt="Boa User Guide"
                      target="_blank"
                    >
                      user guide
                    </a>{' '}
                    for further use cases BOA can be useful for.
                  </LastPara>
                  {/*<LastPara>Use the Reporting Tab to monitor and review the changes made by BOA's and the overall impact on your campaign.</LastPara>*/}
                  <LastPara></LastPara>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              {/*<CardImg variant="top" src={xposureImage} />*/}
              <Card.Body>
                <CardTitle>How BOA works</CardTitle>
                <Card.Text>
                  <p>
                    If you are here you have probably mastered how to use
                    Copilot to optimise individual line items by now. It’s now
                    time to do something to optimize your budget allocation
                    across all your line items. BOA will do this job for you.{' '}
                  </p>
                  <p>
                    BOA automatically updates budgets for each line item
                    overnight based upon prior outcome performance, increasing
                    the budget for line items performing well, and decreasing
                    the budget for line items having poorer results. To do this
                    BOA takes advantage of a technique called multi-armed bandit
                    optimisation, a form of, so-called, reinforcement learning.
                    A few of the smart things that BOA takes into account to get
                    the optimal budget alllocation include:
                    <ol>
                      <li>Daily pacing.</li>
                      <li>
                        Explore and exploit modes throughout the campaign
                        duration.
                      </li>
                      <li>Explore and exploit modes across Line Items.</li>
                      <li>Minimum spend caps.</li>
                    </ol>
                  </p>
                  <p>
                    Beta testing on a CPC campaign saw a 40% improvement. More
                    case studies will follow.
                  </p>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>What is required to use BOA</CardTitle>
                <Card.Text>
                  To use BOA you need:
                  <ol>
                    <li>To be using Xandr (Appnexus).</li>
                    <li>
                      Have a KPI which is one of the following: clicks (CTR or
                      CPC); Conversions (CR or CPA); or Video Completion (VCR or
                      CPCV).
                    </li>
                    <li>
                      2+ line items. There is no upper limit to the number of
                      line items, but bear in mind the more line items you have
                      the less data per line item there is to learn from, making
                      optimisation less robust.
                    </li>
                    <li>
                      All line Items need to be ALIs and within a single IO.
                    </li>
                    <li>
                      All Line Items need to be available for the same duration.
                    </li>
                    <li>Campaign duration > 7 days.</li>
                  </ol>
                  {/* <StyledLink
                    activeClassName="active"
                    to="/optimisation/boa/"
                    onClick={(e) => e.preventDefault()}
                  >
                    Try it out
                  </StyledLink> */}
                  <StyledLinkLong activeClassName="active" to="/dashboard">
                    Return to Dashboard
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled.div`
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 538px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

const LastPara = styled.p`
  margin-bottom: 11.8rem;
`;

const StyledLink = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 8.5rem;
    display: flex;
    width: 80px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 2rem;
    display: flex;
    width: 150px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

/* const CarouselItem = styled(Carousel.Item)`
  height: 200px;
  border-radius: 4px;
  min-height: 340px;
  overflow: hidden;
`; */

/* const CarouselImage = styled.img` */
/* object-fit: cover; Scale the image so it covers whole area, thus will likely crop */
/* object-position: center; Center the image within the element */
/* height: 100%; */
/* width: 100%; */
/* filter: blur(3px) brightness(80%); */
/* overflow: hidden; */
/* transform: scale(1.1); */
// `;

export default Boainfo;
