import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from '../../Reporting/CampaignExplorer/seatIds';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';
import orangeSegments from '../../../assets/img/orangeSegments.jpg';

export class SegmentSharer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.0.1',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID of segment origin',
              options: seats,
            },
          },
          {
            name: 'seatIdDestination',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID of segment destination',
              options: seats,
            },
          },
          {
            name: 'segmentName',
            initialValue: '',
            type: 'text',
            required: true,
            config: {
              placeholder: 'Segment Name',
              options: seats,
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an origin seat ID is required.'),
    seatIdDestination: Yup.string().required('Choosing a destination seat ID is required'),
    segmentName: Yup.string().required('Enter the name of the segment'),
    //csvUpload: Yup.string().required('Please upload a Segment Id csv file'),
  });

  createFormData = (values, email) => {
    return {
      jobId: values.jobId,
      seatId: values.seatId.value,
      seatIdDestination: values.seatIdDestination.value,
      segmentName: values.segmentName,
      userEmail: email,
    };
  };

  submitHandler = values => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then(response => {
        this.postDataHandler(formData);
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = formData => {
    api
      //.post('/segmentsharer/putFormData', formData)
      .post('/segmentsharer/segmentSharerDev', formData)
      //.post('/segmentSharerDev', formData)
      .then(response => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            //id: response.data.id,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch(error => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then(data => console.log('signing out', data))
      .catch(err => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Campaign Set-up',
      levels: [
        {
          name: 'Segment Sharer - the Xaxis segment sharing tool',
          link: '/campaign-set-up/segment-sharer',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
        <Col xl={3} lg={3} md={3} sm={12} className='cardandForm'>
            <DashboardCard className="orange second">
              <CardImg variant="top" src={orangeSegments} />
              <Card.Body>
                <CardTitle>SEGMENT SHARER (Coming Soon!)</CardTitle>
                <Card.Text>
                A segment sharering tool. 
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={9} lg={9} md={9} sm={12} className='cardandForm2'>
          <Card className="xtCard">
            <Card.Body>
              <h1>Segment Sharer</h1>
              {this.state.hasSubmitted ? (
                response
              ) : (
                <React.Fragment>
                  <p>
                    Select the Seat Id of the segment's origin and the Seat Id the segment will be shared with.
                  </p>
                  <Form
                    setSubmitted={this.setSubmitted}
                    controls={this.state.form}
                  />
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: black;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(SegmentSharer);
