import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';
import scoutImage from '../../../assets/img/scout.jpg';

const GoogleSearchAlgoinfo = () => {
  const subheader = {
    main: 'Optimisation',
    levels: [
      {
        name: 'Google Search Trends Algorithm Information',
        link: '/optimisation/googlesearchalgoinfo',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={scoutImage} />
              <Card.Body className="cardBodyFirst">
                <CardTitle>GOOGLE SEARCH TRENDS ALGORITHM</CardTitle>
                <Card.Text>
                  The Google Search Trends Algorithm is an optimization
                  algorithm based on Google Trends data. It’s DSP agnostic
                  meaning you can deploy it with any DSP.
                  <LastPara1></LastPara1>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>
                  How the Google Search Trends Algorithm Works
                </CardTitle>
                <Card.Text>
                  The Google Search Trends Algorithm presents a new way of
                  optimising upper funnel KPIs. It pulls data from Google Trends
                  to look at the search activity of different keywords that are
                  relevant to the campaign. This information is used to create a
                  bid modifier with unique bids for each day, hour and
                  optionally region. The aim is to target times with a
                  particularly high interest. The result is a csv file that can
                  be uploaded via Copilot to build a Custom Tree. This method
                  can be used with no input from the client and is useful for
                  new campaigns, where there is limited data available.
                  <LastPara></LastPara>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>
                  What is required to use the Google Search Trends Algorithm?
                </CardTitle>
                <Card.Text>
                  To use the Google Search Trends Algorithm, you will need a
                  list of relevant keywords and analysis timeframe we should
                  apply to build the algorithm.
                  <ul>
                    <li>
                      List of keywords: We recommend using the mixture of
                      branded and product keywords for the maximum performance.
                      The total number of keywords provided should be between 20
                      and 100.{' '}
                    </li>
                    <li>
                      Analysis timeframe: We typically collect about 4-6 weeks
                      of data before the campaign start, so that the data is
                      relevant. With the Google Trends API, we can go back up to
                      5 years, so for a winter campaign we could look at search
                      trends from last winter and create a bid modifier based on
                      last season data.{' '}
                    </li>
                  </ul>
                  Reach out to{' '}
                  <a href="mailto:XAXEMEAMarketingScience@xaxis.com">
                    XAXEMEAMarketingScience@xaxis.com
                  </a>{' '}
                  to learn more or to test this capability.
                  {/* <StyledLink
                    activeClassName="active"
                    to="/optimisation/googlesearchalgo"
                  >
                    Try it out
                  </StyledLink> */}
                  <StyledLinkLong activeClassName="active" to="/dashboard">
                    Return to Dashboard
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

// const StyledDashboard = styled(Card.Body)`
// background:red; &:first-child {
//       background: black;
//         color: green!important;
//     }
//     `;

const StyledDashboard = styled(Card.Text)`
  & a {
    color: red;
  }
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 495px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
`;

const LastPara = styled.p`
  margin-bottom: 12.2rem;
`;

const LastPara1 = styled.p`
  margin-bottom: 14rem;
`;

const StyledLink = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 2rem;
    display: flex;
    width: 80px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: red
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: red;
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 1rem;
    display: flex;
    width: 150px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default GoogleSearchAlgoinfo;
