import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';

import autoscanImage from '../../../assets/img/autoscan.jpg';
import autoscanLogoImage from '../../../assets/img/autoscan-logo.png';

const AutoScaninfo = () => {
  const subheader = {
    main: 'AutoScan',
    levels: [
      {
        name: 'AutoScan',
        link: '/autoscan',
      },
    ],
  };

  return (
    <React.Fragment>
      <Subheader config={subheader} />
      <StyledDashboard>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={autoscanImage} />
              <LogoImg variant="top" src={autoscanLogoImage} />
              <Card.Body>
                <CardTitle>All about Autos-can</CardTitle>
                <Card.Text>
                  If you are looking for a way to perform tag audits more
                  efficiently,{' '}
                  <a
                    href="//autoscan.ihub.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Autoscan
                  </a>{' '}
                  is what you need. It allows you to scan websites for pixels
                  and tags automatically to ensure they are present and firing
                  on the page.
                </Card.Text>
                <Card1LastPara>
                  You can find more information (including training material on
                  how to use Autoscan) at{' '}
                  <a
                    href="//intro.autoscan.ihub.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    intro.autoscan.ihub.link
                  </a>
                  .
                </Card1LastPara>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>How Autoscan Works</CardTitle>
                <Card.Text>
                  <span className="sub-title">Tag Scan</span>
                </Card.Text>
                <Card.Text>
                  With ‘Tag Scan’ you can check if a specific pixel is available
                  on a URL or sections of a website. By creating a ‘spec file’,
                  you can check many different URLs and pixels in one single
                  report.
                </Card.Text>

                <span className="sub-title">Site Audit</span>
                <Card.Text>
                  With a ‘Site Audit’ you can check which tags or pixels are
                  currently live on an advertiser’s or publisher’s website
                  without having to provide any specific pixel(s) to look for.
                  This is helpful to check if the site has been tagged
                  correctly. It can also be used to detect any changes to a site
                  structure after the tagging has been completed.
                </Card.Text>
                <Card2LastPara>
                  Intro & training PPT can be{' '}
                  <a
                    href="https://xaxistech.atlassian.net/wiki/download/attachments/827786048/Autoscan-v2-Training%202019.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    downloaded here.
                  </a>
                </Card2LastPara>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>What is required to use Autoscan</CardTitle>
                <Card.Text>
                  <span className="sub-title">Who can use Autoscan?</span>
                </Card.Text>
                <Card.Text>
                  All Xaxis businesses, regions, and markets! New regions or
                  markets that want to start using it will have to request an
                  initial setup by the Autoscan admin team.
                </Card.Text>

                <span className="sub-title">How do I sign on?</span>
                <Card.Text>
                  Any GroupM or Xaxis employee can log in using their Single
                  Sign-On (SSO) credentials.
                </Card.Text>
                <Card.Text className="bold">
                  Please be aware that after your first login an Autoscan admin
                  will have to activate your user. This is usually done
                  automatically within 1-3 days.
                </Card.Text>
                <Card3LastPara>
                  You can also email the team directly at{' '}
                  <a className="email" href="mailto:autoscan.admin@xaxis.com">
                    autoscan.admin@xaxis.com
                  </a>{' '}
                  and they will create a user for you that is activated right
                  away.
                </Card3LastPara>
                <StyledLink
                  activeClassName="active"
                  to="//autoscan.ihub.link"
                  target="_blank"
                >
                  Go to Autoscan
                </StyledLink>
                <StyledLinkLong activeClassName="active" to="/dashboard">
                  Return to Dashboard
                </StyledLinkLong>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled(Card)`
  & a.email:link {
    color: red;
  }
  a.email:hover {
    color: red;
  }
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 502px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
  .sub-title {
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

const LogoImg = styled(Card.Img)`
  width: 90%;
  text-align: center;
  margin: auto;
  padding: 2rem 0 1rem;
`;

const Card1LastPara = styled.p`
  margin-bottom: 2.2rem;
`;

const Card2LastPara = styled.p`
  margin-bottom: 12.4rem;
  font-style: italic;
`;

const Card3LastPara = styled.p`
  font-weight: bold;
`;

const StyledLink = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 3rem;
    display: flex;
    width: 120px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 2rem;
    display: flex;
    width: 150px;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default AutoScaninfo;
