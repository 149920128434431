export const menuToggleHandler = (event, context, type) => {
  event.preventDefault();
  console.log(event);

  function event_handler(e) {
    menuToggleHandler(e, context, type);
  }

  context.setState(
    prevState => {
      return { [type]: !prevState[type] };
    },
    () => {
      if (context.state[type]) {
        // console.log('true', context.state[type]);
        document.addEventListener('click', event_handler, false);
      } else {
        // console.log('false', context.state[type]);
        document.removeEventListener('click', event_handler, false);
      }
    }
  );
};

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const createJobId = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    /* eslint-disable-next-line */
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

// https://stackoverflow.com/questions/1303646/check-whether-variable-is-number-or-string-in-javascript
// second answer
export const isNumber = n => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};
