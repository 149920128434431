import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color } from 'utils/style/variables';
import Form from './Form/Form';
//import seats from './seatIdsExposureTime';
import seats from '../../Reporting/CampaignExplorer/seatIds';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';
import xposureImage from '../../../assets/img/long-exposure-startrail-photography-lincoln-harrison-12.jpg';

export class Xposure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.1.2',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'insertionOrderId',
            initialValue: '',
            type: 'text',
            required: true,
            config: {
              placeholder: 'Insertion Order Id',
            },
          },
          {
            name: 'timeFrame',
            initialValue: 'LastSevenDays',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Time Frame',
              options: [
                { value: 'Yesterday', label: 'Yesterday' },
                { value: 'LastSevenDays', label: 'Last Seven Days' },
                {
                  value: 'LastThirtyDays',
                  label: 'Last Thirty Days',
                },
                {
                  value: 'LastNinetyDays',
                  label: 'Last Ninety Days',
                  isDisabled: true,
                },
              ],
            },
          },
          {
            name: 'timeInterval',
            initialValue: 'Weekly',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Time Interval',
              options: [
                { value: 'Daily', label: 'Daily' },
                { value: 'Monthly', label: 'Monthly' },
              ],
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an advertiser ID is required.'),
    timeFrame: Yup.string().required('Please select a time frame'),
    timeInterval: Yup.string().required('Please select a time interval'),
    insertionOrderId: Yup.number()
      .typeError('The insertion order ID must be a number.')
      .positive('The insertion order ID must be positive.')
      .integer('The insertion order ID must be an integer.')
      .required('Adding an insertion order ID is required.'),
  });

  createFormData = (values, email) => {
    return {
      jobId: values.jobId,
      dateTime: String(Date.now()),
      seatId: values.seatId.value,
      insertionOrderId: values.insertionOrderId,
      //startDate: values.startDate.toISOString().split('T')[0],
      //endDate: values.endDate.toISOString().split('T')[0],
      timeInterval: values.timeInterval.value
        ? values.timeInterval.value
        : null,
      timeFrame: values.timeFrame.value ? values.timeFrame.value : null,
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then((response) => {
        this.postDataHandler(formData);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = (formData) => {
    api
      //.post('/xposure/createXposure', formData)
      .post('/xposuretest/putFormData', formData)
      .then((response) => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Exposure Time',
          link: '/reporting/xposure',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xl={3} lg={3} md={3} sm={12}>
                <DashboardCard className="lighterBlue second">
                  <CardImg variant="top" src={xposureImage} />
                  <Card.Body>
                    <CardTitle>EXPOSURE TIME</CardTitle>
                    <Card.Text>
                      This report enables traders to monitor the Exposure Time
                      of campaigns. Use it to calculate how many impressions
                      were viewed for more than 5 seconds, for example, and what
                      the cost or revenue was. For a full list of dimensions and
                      metrics on the report check the downloadable files.{' '}
                    </Card.Text>
                  </Card.Body>
                </DashboardCard>
              </Col>
              <Col xl={9} lg={9} md={9} sm={12}>
                <Card className="xtCard">
                  <Card.Body>
                    <h1>
                      <b>Exposure Time</b>
                    </h1>
                    {this.state.hasSubmitted ? (
                      response
                    ) : (
                      <React.Fragment>
                        <p>Please fill out the form.</p>
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      </React.Fragment>
                    )}
                    <p>
                      You can download the following CSV file to see an example
                      Exposure Time report:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="/assets/files/XposureSamplereport.csv"
                          alt="Xposure Sample Report"
                          target="_blank"
                        >
                          Exposure Time Sample Report
                        </a>
                      </li>
                    </ul>
                    <p>
                      Further information about Exposure, and a set up guide, is
                      available here:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="/assets/files/ExposureTimeReportdocumentation.docx"
                          alt="Xposure Time Report documentation"
                          target="_blank"
                        >
                          Exposure Time Additional Information
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://xaxistech.atlassian.net/wiki/spaces/EMEAPE/pages/697532445/Exposure+Time"
                          alt="Set Up Information"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Exposure Time Setup and Further Information
                        </a>
                      </li>
                    </ul>
                    <p>
                      Further information about viewability and exposure time
                      levels in EMEA
                    </p>
                    <ul>
                      <li>
                        <a
                          href="/assets/files/EMEA VIEWABILITY AND EXPOSURE TIME.pdf"
                          alt="Set Up Information"
                          target="_blank"
                        >
                          EMEA viewability and exposure time by media and supply
                          type
                        </a>
                      </li>
                    </ul>
                    <p>Product Champion: Goncalo Cruz</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: black;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

// const CardGraphic = styled.img`
//   backface-visibility: hidden;
//   transition: all 0.5s;
// `;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(Xposure);
