import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';
import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from './seatIdsYoutubs';
import scrabbleImage from '../../../assets/img/scrabble.jpg';
import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';

export class ContextualInsights extends React.Component {
  constructor(props) {
    super(props);
    let seats = [];
    if (this.props.responseData !== undefined) {
      for (var i = 0; i <= this.props.responseData.length - 1; i++) {
        if (this.props.responseData[i].Tool === 'Contextual Insights') {
          var c = {};
          c.value = this.props.responseData[i].SeatID;
          c.label = this.props.responseData[i].SeatName;
          if (this.props.responseData[i].IsActive === 'Yes') {
            c.isDisabled = false;
          } else {
            c.isDisabled = true;
          }
          seats.push(c);
        }
      }
    }
    this.state = {
      version: '0.1.2',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'insertionOrderId',
            initialValue: '',
            type: 'number',
            required: true,
            config: {
              placeholder: 'Insertion Order ID',
              explanation:
                'Please insert a single insertion order. Only numeric characters are allowed in this field.',
            },
          },
          {
            name: 'OutcomeType',
            initialValue: {
              value: 'CTR',
              label: 'CTR',
            },
            type: 'select',
            required: true,
            config: {
              placeholder: 'Outcome Type',
              options: [
                {
                  value: 'Conversion',
                  label: 'Conversion',
                },
                {
                  value: 'CTR',
                  label: 'CTR',
                },
              ],
              defaultValue: 1,
            },
          },
          {
            name: 'VCREnabled',
            initialValue: {
              value: 'CTR',
              label: 'CTR',
            },
            type: 'select',
            required: true,
            config: {
              placeholder: 'Outcome Type',
              options: [
                {
                  value: 'Conversion',
                  label: 'Conversion',
                },
                {
                  value: 'CTR',
                  label: 'CTR',
                },
                {
                  value: 'VCR',
                  label: 'VCR',
                },
              ],
              defaultValue: 1,
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an Seat ID is required.'),
    OutcomeType: Yup.string().required('Choosing an outcome type is required.'),
    insertionOrderId: Yup.number()
      .typeError('The insertion order ID must be a number.')
      .positive('The insertion order ID must be positive.')
      .integer('The insertion order ID must be an integer.')
      .required('Adding an insertion order ID is required.'),
  });

  createFormData = (values, email) => {
    var insertionOrderId = values.insertionOrderId;
    var date = new moment().format('YYYY-MM-DD hh:mm:ss');
    return {
      jobId: values.jobId,
      RunDate: String(date),
      seatId: values.seatId.value,
      insertionOrderId: insertionOrderId,
      OutcomeType:
        values.seatId.label === 'Xaxis - Belgium (1847)'
          ? values.VCREnabled.value
          : values.OutcomeType.value,
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    this.setState({ submitting: true });

    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.postDataHandler(formData);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = (formData) => {
    api
      .post('/contextualInsights/putFormData', formData)
      .then((response) => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Contextual Insights',
          link: '/reporting/contextual-insights',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />

        <StyledContainer>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xl={3} lg={3} md={3} sm={12}>
                <DashboardCard className="greyc500 second">
                  <CardImg variant="top" src={scrabbleImage} />
                  <Card.Body>
                    <CardTitle>
                      <b>Contextual Insights</b>
                    </CardTitle>
                    <Card.Text>
                      Contextual Insights uses your campaigns’ performance data
                      to produce an insight for you. By using the insight, you
                      could learn which content categories are likely to improve
                      your campaign’s performance. Also, you could save your
                      budget by not spending on content categories that are not
                      likely to perform.
                    </Card.Text>
                  </Card.Body>
                </DashboardCard>
              </Col>
              <Col xl={9} lg={9} md={9} sm={12}>
                <Card className="xtCard">
                  <Card.Body>
                    <h1>
                      <b>Contextual Insights</b>
                    </h1>
                    {this.state.hasSubmitted ? (
                      response
                    ) : (
                      <React.Fragment>
                        <p>
                          Please fill out the form – this will start the process
                          to generate your insights.
                        </p>
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      </React.Fragment>
                    )}
                    <p>
                      To find out more about Contextual Insights download the
                      following one pager below:
                    </p>
                    <p>
                      <a
                        href="/assets/files/ContextualInsightSegments.pdf"
                        alt="Contextual Insights Guide"
                        target="_blank"
                      >
                        Contextual Insights Guide
                      </a>{' '}
                      {'\n'}
                    </p>
                    <p>
                      An example of Contextual Insights csv file can be
                      downloaded from below:
                    </p>
                    <p>
                      <a
                        href="/assets/files/index.csv"
                        alt="Contextual Insights csv Example"
                        target="_blank"
                      >
                        Contextual Insights csv Example
                      </a>{' '}
                      {'\n'}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
    responseData: state.auth.Data,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(ContextualInsights);
