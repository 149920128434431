import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { color, transition } from 'utils/style/variables';
import Subheader from 'components/Subheader/Subheader';
import scoutImage from '../../../assets/img/scrabble.jpg';

const ContextualToolbox = () => {
  //  const subheader = {
  //main: 'Optimisation',
  //levels: [],
  // };

  return (
    <React.Fragment>
      {/* <Subheader config={subheader} /> */}
      <StyledDashboard>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <CardImg variant="top" src={scoutImage} />
              <Card.Body className="cardBodyFirst">
                <CardTitle>CONTEXTUAL TOOLBOX</CardTitle>
                <Card.Text>
                  Find out which content type categories drive performance for
                  your campaign. Activate Xaxis proprietary contextual segments.
                  <LastPara1></LastPara1>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>Contextual Insights</CardTitle>
                <Card.Text>
                  Contextual Insights uses your campaigns’ performance data to
                  produce an insight for you. By using the insight, you could
                  learn which content categories are likely to improve your
                  campaign’s performance. Also, you could save your budget by
                  not spending on content categories that are not likely to
                  perform.
                  <StyledLinkLong
                    activeClassName="active"
                    to="/reporting/contextual-insights"
                  >
                    Try Out
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12}>
            <DashboardCard className="greyc500 second">
              <Card.Body>
                <CardTitle>Contextual IAB Segments</CardTitle>
                <Card.Text>
                  Try out our contextual segments we have built for you to
                  activate on Xandr for free.
                  <StyledLinkLong
                    activeClassName="active"
                    to="/campaignsetup/iab-contextual-segments"
                  >
                    Try Out
                  </StyledLinkLong>
                </Card.Text>
              </Card.Body>
            </DashboardCard>
          </Col>
        </Row>
      </StyledDashboard>
    </React.Fragment>
  );
};

const StyledDashboard = styled(Card.Body)`
  padding: 2.12rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 472px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
`;

const LastPara1 = styled.p`
  margin-bottom: 14rem;
`;

const StyledLinkLong = styled(NavLink)`
  &:link,
  &:visited {
    margin-top: 1rem;
    display: flex;
    width: 100px;
    justify-content: center;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    position: absolute;
    bottom: 15px;
    transition: ${transition.default};
    color: ${color.grey.dark};
    border-radius: 4px;
    background-color: ${color.white};

    &:hover,
    &:active,
    &.${(props) => props.activeClassName} {
      color: ${color.primary.default};
      background-color: ${color.grey.bg};
    }
  }
`;

export default ContextualToolbox;
