import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { color, shadow, transition } from 'utils/style/variables';
import Button from 'components/UI/Button/Button';

const ResponseMessage = props => (
  <Response {...props.config.error}>
    <Row>
      <Icon>
        {props.config.error ? (
          <FontAwesomeIcon size="4x" icon={faTimes} />
        ) : (
          <FontAwesomeIcon size="4x" icon={faCheck} />
        )}
      </Icon>
    </Row>
    <Row>
      <Status>{props.config.error ? 'Error!' : 'Success!'}</Status>
    </Row>
    <Row>
      <Message>
        <p>{props.config.message}</p>
      </Message>
    </Row>
    <Row>
      <Message>
        <p>{props.config.jobId}</p>
      </Message>
    </Row>
    <Row>
      <Action>
        <Button onClick={props.resetForm} primary>
          {props.config.error ? 'Try again' : 'Another request'}
        </Button>
      </Action>
    </Row>
  </Response>
);

const Response = styled(Container)`
  box-shadow: ${shadow.card};
  background-color: ${props => (props.error ? color.error : color.success)};
  border: 1px solid ${props => (props.error ? color.error : color.success)};
  border-radius: 4px;
  transition: ${transition.default};
  width: 25rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Icon = styled(Col)`
  color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const Status = styled(Col)`
  color: ${color.white};
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 2rem 2rem;
`;

const Message = styled(Col)`
  background-color: ${color.white} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
`;

const Action = styled(Col)`
  border-radius: 0 0 4px 4px;
  background-color: ${color.white} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export default ResponseMessage;
