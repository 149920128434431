import React, { Component } from 'react';
// import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { color } from 'utils/style/variables';
import toCurrency from './Currency.service';

class CurrencyInput extends Component {
  static defaultProps = {
    separator: '.',
  };

  state = {
    value: this.props.defaultValue || '',
  };

  handleChange = event => {
    const { onChange, separator, field } = this.props;
    const valueAsCurrency = toCurrency(event.target.value, separator);

    this.setState({ value: valueAsCurrency });

    if (onChange) {
      event.persist();
      onChange(field.name, valueAsCurrency);
    }
  };

  get value() {
    return this.props.value || this.state.value;
  }

  render() {
    const {
      handleChange,
      props: {
        form: {
          setFieldTouched,
          // values: { currency },
        },
        field,
        defaultValue,
        separator,
        ...inputProps
      },
      value,
    } = this;

    return (
      <>
        {/* <StyledInputGroup> */}
        {/* <div className="input-group-prepend"> */}
        {/* <InputGroupText className="input-group-text"> */}
        {/* {currency} */}
        {/* </InputGroupText> */}
        {/* </div> */}
        <InputElement
          name={field.name}
          placeholder="0.00"
          {...inputProps}
          value={value}
          onChange={handleChange}
          onBlur={e => setFieldTouched(field.name, e)}
        />
        {/* </StyledInputGroup> */}
      </>
    );
  }
}

const InputElement = styled.input`
  padding: 0.65rem 1rem;
  outline: 0 none !important;
  box-shadow: none;
  min-height: 38px;
  outline: none;
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid ${color.grey.c200};
  border-radius: 4px;
  transition: $transition-3;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.grey.c500};
    outline: 0 none !important;
  }
`;

// const StyledInputGroup = styled(InputGroup)`
// `;

// const InputGroupText = styled.div`
//   width: 4rem;
//   font-weight: 200;
//   font-size: 1.4rem;
//   justify-content: center;
//   border: 1px solid $gray-200;
//   background-color: $gray-100;
// `;

export default CurrencyInput;
