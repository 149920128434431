import React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Col, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { color, transition } from 'utils/style/variables';
import VikingLogo from 'assets/img/Viking_Logo.png';

import Select from 'components/Form/Select/Select';
import Spinner from 'components/UI/Spinner/Spinner';
import Button from 'components/UI/Button/Button';
import { createJobId } from 'utils/functions';

class XaxisForm extends React.Component {
  state = {
    response: {
      error: null,
      message: '',
      jobId: '',
    },
    hasSubmitted: false,
    submitting: false,
  };

  submitHandler = (values, { setSubmitting }) => {
    this.props.controls.submit(values);
    setSubmitting(false);
    this.props.setSubmitted();
  };

  initialValues = this.props.controls.fields.reduce(
    (obj, field) => {
      return {
        ...obj,
        [field.name]: field.initialValue,
      };
    },
    { id: createJobId() }
  );

  render() {
    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const content = (
      <Formik
        // this should enable the form re-render if the the initial values are changed
        initialValues={this.initialValues}
        validationSchema={this.props.controls.validation}
        onSubmit={this.submitHandler}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => {
          const fields = this.props.controls.fields.map((field) => {
            if (field.type === null) {
              return null;
            }

            // default field - if anything other than select or date
            let inputField = (
              <StyledField name={field.name} type={field.type} />
            );

            if (field.type === 'select') {
              inputField = <Field component={Select} field={field} required />;
            }

            let row = (
              <Row key={field.name}>
                <Col md={4}>
                  <Label>
                    {field.config.placeholder}{' '}
                    {field.required ? <Required>*</Required> : null}
                  </Label>
                </Col>
                <Col md={8}>
                  {inputField}
                  <StyledErrorMessage
                    name={field.name}
                    className={['alert', 'alert-danger'].join(' ')}
                    component="div"
                  />
                </Col>
              </Row>
            );

            // don't show outcomeGoal if outcome type is targeted reach
            if (field.name === 'outcomeGoal') {
              row = values.outcomeType.value !== 'TARGETED_REACH' ? row : null;
            }

            // currently not needed - keeping it in for future use
            // only show pixelId input if outcome type is conversion
            // if (field.name === 'pixelId') {
            // row = (values.outcomeType.value === 'CONVERSION') ? row : null;
            // }

            // only show pan regional country select input if pan regional seat is chosen
            if (field.name === 'panRegionalCountry') {
              // row =
              // values.seatId.value === 1845
              // ? row
              // : values.seatId.value === 7942
              // ? row
              // : null;
              row = values.seatId.value === 9999 ? row : null;
            }

            return row;
          });

          let previewImage;
          let previewImageAlt;

          previewImage = 'assets/img/Viking_Logo.png';
          previewImageAlt = 'Viking Logo Image';

          previewImage = VikingLogo;

          return (
            <React.Fragment>
              <Form className="form-group">
                <Row>
                  <RequiredNote md={6}>
                    Fields marked with a <Required>*</Required> are required.
                  </RequiredNote>
                </Row>
                <Row>
                  <Col md={6}>{fields}</Col>
                  <TemplatePreviewContainer md={6}>
                    <TemplatePreview src={previewImage} alt={previewImageAlt} />
                  </TemplatePreviewContainer>
                </Row>
                <Row>
                  <ButtonCol>
                    <Button type="submit" disabled={isSubmitting} primary>
                      Submit
                    </Button>
                  </ButtonCol>
                </Row>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );

    return this.state.submitting ? spinnerContainer : content;
  }
}

const StyledField = styled(Field)`
  padding: 0.65rem 1rem;
  box-shadow: none;
  min-height: 38px;
  outline: none;
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid ${color.grey.c200};
  border-radius: 4px;
  transition: ${transition.default};

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.grey.c500};
  }
`;

const Label = styled.label`
  display: flex;
  padding-right: 1rem;
  width: 100%;
  min-height: 38px;
  align-items: center;
`;

const Required = styled.span`
  color: red;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const RequiredNote = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.65rem 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-top: 0.2rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: none;
  margin-left: 6px;
  width: 90%;
  transition: ${transition.default};
`;

const ButtonCol = styled(Col)`
  padding-top: 0.51rem;
`;

const TemplatePreview = styled.img`
  width: 18rem;
  border-radius: 4px;
  transition: ${transition.default};
  margin: 0rem 1rem 2rem 1rem;
`;

const TemplatePreviewContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

export default XaxisForm;
