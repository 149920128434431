import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auth';

// make redux dev plugin work only in development mode
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({ auth: authReducer });
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;