import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';

import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from './seatIds';

import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';

export class CampaignAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.5.5',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'panRegionalCountry',
            initialValue: '',
            type: 'select',
            config: {
              placeholder: 'Country',
              options: seats,
            },
          },
          {
            name: 'currency',
            initialValue: '\u20AC',
            type: null,
            config: null,
          },
          {
            name: 'insertionOrderId',
            initialValue: [],
            type: 'tags',
            required: true,
            config: {
              placeholder: 'Insertion Order IDs',
              explanation:
                'For aggregated IO report please type one ID and press space before proceeding to the next. The ID will be highlighted in blue if correct.',
            },
          },
          //{
          //name: 'insertionOrderId',
          //initialValue: '',
          //type: 'text',
          //required: true,
          //config: {
          //placeholder: 'Insertion Order ID',
          //},
          //},
          {
            name: 'dateRange',
            initialValue: null,
            type: 'date',
            required: true,
            config: {
              dateRange: true,
              start: null,
              end: null,
              placeholder: 'Date Range',
            },
          },
          {
            name: 'outcomeType',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'OutcomeType',
              options: [
                { value: 'CLICK', label: 'Click' },
                { value: 'CONVERSION', label: 'Conversion' },
                {
                  value: 'VIEWABILITY',
                  label: 'Viewability',
                },
                {
                  value: 'VIDEO_COMPLETION_RATE',
                  label: 'Video Completion Rate - Now available!',
                },
                {
                  value: 'TARGETED_REACH',
                  label: 'Targeted Reach - Coming Soon!',
                  isDisabled: true,
                },
              ],
            },
          },
          {
            name: 'pixelId',
            initialValue: [],
            type: 'tags',
            config: {
              placeholder: 'Pixel IDs',
              explanation:
                'For an aggregated Pixel ID report please type one Pixel ID and press space before proceeding to the next. The Pixel ID will be highlighted in blue if correct.',
            },
          },
          {
            name: 'outcomeGoal',
            initialValue: '',
            type: 'text',
            config: {
              placeholder: 'Outcome Goal',
            },
          },
          {
            name: 'metricType',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Metric Type',
              options: [
                { value: 'Absolute', label: 'Show revenue metrics' },
                { value: 'Relative', label: 'Show indexed revenue metrics' },
              ],
            },
          },
          {
            name: 'template',
            initialValue: 'XAXIS',
            type: 'select',
            required: true,
            config: {
              placeholder: 'PowerPoint Template',
              options: [
                // { value: "BLANK", label: "Blank" },
                { value: 'GROUPM', label: 'GroupM' },
                { value: 'XAXIS', label: 'Xaxis' },
                { value: 'MINDSHARE', label: 'Mindshare' },
                { value: 'WAVEMAKER', label: 'Wavemaker' },
                { value: 'MEDIACOM', label: 'Mediacom' },
              ],
              // references array position of default in options array
              defaultValue: 1,
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        id: '',
      },
      hasSubmitted: false,
      submitting: false,
      selectedOutcomeType: null,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing a seat ID is required.'),
    insertionOrderId: Yup.array()
      .of(
        Yup.number()
          .positive('The insertion order ID must be a positive number')
          .integer('The insertion order ID must be an integer.')
      )
      .required('Adding an insertion order ID is required.'),
    startDate: Yup.date().required('A start date is required.'),
    endDate: Yup.date().required('An end date is required.'),
    outcomeType: Yup.string().required('Choosing an outcome type is required.'),
    outcomeGoal: Yup.number().positive('The outcome goal must be positive.'),
     metricType: Yup.string().required('Choosing an metric type is required.'),
    pixelId: Yup.array().of(
      Yup.number().positive('Pixel Id must be a positive number')
    ),
    template: Yup.string().required('Please choose a template.'),
  });

  // Remove commenting below to test form without validation
  // validationSchema = null;

  createFormData = (values, email) => {
    var insertion = values.insertionOrderId;
    var insertionOrderTogether = insertion.join();
    var pixel = values.pixelId;
    var pixelIdsTogether = pixel.join();
    return {
      id: values.id,
      seatId: values.seatId.value,
      //panRegionalCountry: values.panRegionalCountry.value,
      insertionOrderId: values.insertionOrderId,
      insertionOrderIdList: insertionOrderTogether,
      startDate: values.startDate.toISOString().split('T')[0],
      endDate: values.endDate.toISOString().split('T')[0],
      outcomeType: values.outcomeType.value,
      outcomeGoal: values.outcomeGoal ? values.outcomeGoal : null,
      pixelId: values.pixelId ? values.pixelId : null,
      pixelIdMultiple: pixelIdsTogether ? pixelIdsTogether : null,
      metricType: values.metricType,
      template: values.template.value ? values.template.value : values.template,
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then((response) => {
        this.postDataHandler(formData);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  // use below to test form submission
  // submitHandler = (values) => {
  //   const formData = this.createFormData(values, this.props.email);
  //   console.log('formData', formData);
  //   console.log(this.state.outcomeTypeSelected);
  // };

  postDataHandler = (formData) => {
    api
      .post('/campaignExplorerv2/putFormData', formData)
      .then((response) => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            id: response.data.id,
            error: false,
          },
        });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      id: '',
      responseError: null,
    });
  };

  typeUpdateHandler = (type) => {
    this.setState({ selectedOutcomeType: type });
    console.log(this.state.outcomeTypeSelected);
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Campaign eXplorer',
          link: '/reporting/campaign-explorer',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
          <Card className="xtCard">
            <Card.Body>
              <h1>
                <b>Campaign eXplorer</b>
              </h1>
              {this.state.hasSubmitted ? (
                response
              ) : (
                <React.Fragment>
                  <p>
                    Please fill out the form - this will start the process to
                    generate the PowerPoint Template.
                  </p>
                  <Form
                    setSubmitted={this.setSubmitted}
                    controls={this.state.form}
                    typeUpdateHandler={this.typeUpdateHandler}
                  />
                </React.Fragment>
              )}
              <p>
                You can download the following PDF documents if you need an
                explanation in regards to Campaign eXplorer's features and
                functionality:
              </p>
              <ul>
                <li>
                  <a
                    href="/assets/files/Campaign eXplorer User Guide.pdf"
                    alt="Campaign eXplorer User Guide"
                    target="_blank"
                  >
                    Campaign eXplorer User Guide
                  </a>
                  <p>
                    Provides a summary of how to export a report from Campaign
                    eXplorer and how to interpret the output.
                  </p>
                </li>
                <li>
                  <a
                    href="/assets/files/Campaign eXplorer Intro Deck.pdf"
                    alt="Campaign eXplorer Intro Deck"
                    target="_blank"
                  >
                    Campaign eXplorer Intro Deck
                  </a>
                  <p>
                    Provides a summary of the rationale and motivation for
                    building Campaign eXplorer. Example report and some
                    potential upcoming developments also included.
                  </p>
                </li>
              </ul>
              <p>
                <a
                  href="https://teams.microsoft.com/l/channel/19%3ad62677fada06410b80440244999134f9%40thread.skype/EMEA-Campaign-eXplorer?groupId=d55d7eb5-5db7-4179-81d9-f886b7bea4a6&tenantId=86cbe1bb-213f-4271-b174-bd59d03c87a3"
                  alt="Link to Teams"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Campaign Explorer Teams site
                </a>
              </p>
              <p>Product Champion: James Barrett</p>
            </Card.Body>
          </Card>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

export default connect(mapStateToProps)(CampaignAnalysis);
