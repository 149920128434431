import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';
import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from '../../Reporting/CampaignExplorer/seatIds';
import espressoImage from '../../../assets/img/espresso.jpg';
import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';

export class EspressoProfiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '0.1.2',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
          {
            name: 'analysisType',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Analysis Type',
              options: [
                {
                  value: 'SEGMENT-PROFILE',
                  label: 'Segment Profile (Now live!)',
                },
                {
                  value: 'Campaign-Profile',
                  label: 'Campaign Profile (Now live!)',
                },
              ],
              defaultValue: 0,
            },
          },
          {
            name: 'segmentId',
            initialValue: [],
            type: 'tags',
            required: true,
            config: {
              placeholder: 'Segment ID(s)',
              explanation: 'Enter a Space after your Id',
            },
          },
          // {
          //   name: 'pixelId',
          //   initialValue: [],
          //   type: 'tags',
          //   config: {
          //     placeholder: 'Pixel IDs',
          //     explanation:
          //       'For an aggregated Pixel ID report please type one Pixel ID and press space before proceeding to the next. The Pixel ID will be highlighted in blue if correct.',
          //   },
          // },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      response: {
        error: null,
        message: '',
        jobId: '',
      },
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an advertiser ID is required.'),
    analysisType: Yup.string().required('Select an analysis type'),
    segmentId: Yup.string().required('At least one value is required'),

    //segmentId: Yup.number()
    //.typeError('The insertion order ID must be a number.')
    //.positive('The insertion order ID must be positive.')
    //.integer('The insertion order ID must be an integer.')
    //.required('Adding an insertion order ID is required.'),
    // segmentId: Yup.array().of(
    //   Yup.number().required('At least one segment Id is required')
    // ),
    // pixelId: Yup.array().of(
    //   Yup.number().positive().required('At least one segment Id is required')
    // ),
  });

  createFormData = (values, email) => {
    var date = new moment().format('YYYY-MM-DD hh:mm:ss');
    if (values.analysisType.value === 'Campaign-Profile') {
      var segments = values.segmentId;
      var lineItemId = segments.join();
      var segmentsTogether = null;
    } else {
      var segments = values.segmentId;
      var segmentsTogether = segments.join();
      var lineItemId = null;
    }

    return {
      jobId: values.jobId,
      RunDate: String(date),
      seatId: values.seatId.value,
      segmentId: segmentsTogether,
      lineItemId: lineItemId,
      analysisType: values.analysisType.value,
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then((response) => {
        this.postDataHandler(formData);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = (formData) => {
    api
      .post('/espressoprofiling/putFormData', formData)
      .then((response) => {
        this.setState({ submitting: false });
        this.setState({
          response: {
            message: response.data.message,
            jobId: response.data.jobId,
            error: false,
          },
        });
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.setState({ responseMessage: error.message, responseError: true });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      jobId: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Reporting',
      levels: [
        {
          name: 'Espresso Audience Profiling',
          link: '/reporting/espressoprofiling',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />
        <StyledContainer>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xl={3} lg={3} md={3} sm={12}>
                <DashboardCard className="espresso second">
                  <CardImg variant="top" src={espressoImage} />
                  <Card.Body>
                    <CardTitle>
                      ESPRESSO AUDIENCE PROFILING (Try Now!)
                    </CardTitle>
                    <Card.Text>
                      This report allows you to understand the interests of
                      audiences interacting with your programmatic activities,
                      using IABv2 minsights segments. Activities included are
                      campaign performance; impressions, clicks and conversions
                      but also segment/website pixels.
                    </Card.Text>
                    <Card.Text>
                      These profiles' data looks at the past 30 days.
                    </Card.Text>
                  </Card.Body>
                </DashboardCard>
              </Col>
              <Col xl={9} lg={9} md={9} sm={12}>
                <Card className="xtCard">
                  <Card.Body>
                    <h1>
                      <b>Espresso Audience Profiling</b>
                    </h1>
                    {this.state.hasSubmitted ? (
                      response
                    ) : (
                      <React.Fragment>
                        <p>Please fill out the form.</p>
                        <p>Press 'Space' after entering a Segment Id.</p>
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      </React.Fragment>
                    )}
                    <p>
                      You can download the following Excel file to see an
                      example of the Espresso Audience Profiling report:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="/assets/files/espressodocs/Example_Output.xlsx"
                          alt="Espresso Sample Report"
                          target="_blank"
                        >
                          Espresso Audience Profiling Example Report
                        </a>
                      </li>
                    </ul>
                    <p>
                      Further information about Espresso Audience Profiling, and
                      a user guide, is available here:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="/assets/files/espressodocs/Expresso Profiling User Guide.pptx"
                          alt="Espresso Audience Profiling User Guide"
                          target="_blank"
                        >
                          Espresso Audience Profiling User Guide
                        </a>
                      </li>
                    </ul>
                    <p>Product Champion: Monica Nadlang</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const blueText = styled.p`
  color: rgb(40, 173, 226);
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }
  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

// const CardGraphic = styled.img`
//   backface-visibility: hidden;
//   transition: all 0.5s;
// `;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

// const StyledLinkTopMargin = styled(NavLink)`
//   &:link,
//   &:visited {
//     margin-top: 7rem;
//     display: flex;
//     width: 80px;
//     align-items: center;
//     text-decoration: none;
//     padding: 0.6rem 1.2rem;
//     transition: ${transition.default};
//     color: ${color.grey.dark};
//     border-radius: 4px;
//     background-color: ${color.white};
//     &:hover,
//     &:active,
//     &.${(props) => props.activeClassName} {
//       color: ${color.primary.default};
//       background-color: ${color.grey.bg};
//     }
//   }
// `;

export default connect(mapStateToProps)(EspressoProfiling);
