import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';

import classes from './UserNav.module.scss';
import UserDropdown from './UserDropdown/UserDropdown';
import Notifications from './Notifications/Notifications';

import * as actions from 'store/actions';
import Button from 'components/UI/Button/Button';

// TODO: split into dropdown component and then the content that is rendered within
// that way both notifications and userdropdown component can use the same
// functionality
const UserNav = props => {
  const logoutHandler = () => {
    props.onLogout();
  };

  return (
    <React.Fragment>
      <div className={classes.UserNav}>
        <div
          onClick={props.clickedNotifications}
          className={classes.UserNavIconBox}
        >
          <FontAwesomeIcon
            icon={faBell}
            size="2x"
            className={classes.UserNavIcon}
          />
        </div>
        {props.showNotifications && (
          <div className={classes.CursorFix}>
            <Notifications />
          </div>
        )}
        <div onClick={props.clicked} className={classes.UserNavIconBox}>
          <FontAwesomeIcon
            icon={faUser}
            size="2x"
            className={classes.UserNavIcon}
          />
        </div>
        {props.show && (
          <div className={classes.CursorFix}>
            <UserDropdown />
          </div>
        )}
        <Button onClick={logoutHandler} className={classes.Button} outline>
          Sign out
        </Button>
      </div>
    </React.Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserNav);
