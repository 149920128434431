import * as actionTypes from './actionTypes';
import { Auth } from 'aws-amplify';
import api from 'axios-instance';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId, userEmail, Data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    userEmail: userEmail,
    Data: Data,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = () => {
  return (dispatch) => {
    dispatch(authStart());
  };
};

export const logout = () => {
  return (dispatch) => {
    Auth.signOut()
      .then(() => {
        dispatch(authLogout());
      })
      .catch((error) => {
        console.log('logout error', error);
      });
  };
};

export const authCheck = () => {
  return (dispatch) => {
    dispatch(authStart());
    Auth.currentSession()
      .then((response) => {
        api.defaults.headers.common['Authorization'] =
          response.idToken.jwtToken;

        Auth.currentAuthenticatedUser().then(() => {
          api.post('/allToolsSeats/getData').then((responses) => {
            var Data = responses.data.sort(function (a, b) {
              return a.id - b.id || a.name.localeCompare(b.name);
            });
            dispatch(
              authSuccess(
                response.idToken.jwtToken,
                response.idToken.payload.sub,
                response.idToken.payload.email,
                Data
              )
            );
          });
        });
      })
      .catch((error) => {
        dispatch(authFail(error.message));
      });
  };
};
