import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import config from 'config.js';

import { color } from 'utils/style/variables';
import { hexToRgb } from 'utils/style/functions';
import styled from 'styled-components';

const Auth = () => {
  const submitHandler = event => {
    event.preventDefault();
    window.location.href = config.auth.REDIRECT_LINK;
  };

  return (
    <StyledContainer fluid={true}>
      <StyledRow>
        <Brand>
          <Button onClick={submitHandler}>Login to Xaxis Tools</Button>
        </Brand>
      </StyledRow>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  background: no-repeat center url('/assets/img/xaxis_logo_large.png');
  background-size: contain;
  @media (min-width: 768px) {
    background-size: 70rem;
  }
`;

const StyledRow = styled(Row)`
  height: 100vh;
  align-items: center;
`;

const Brand = styled(Col)`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  &,
  &:link,
  &:visited {
    margin: auto;
    height: 5rem;
    width: 30rem;
    text-decoration: none;
    padding: 1.3rem 4rem;
    border-radius: 10rem;
    transition: all 0.5s ease 0s;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    border: none;
    cursor: pointer;
    background-color: ${color.white};
    color: ${color.grey.dark};
    box-shadow: 0 0.1rem 1rem 0 rgba(${hexToRgb(color.black)}, 0.2);
  }

  &:hover {
    outline: none;
    box-shadow: 0 0.5rem 1.6rem 0 rgba(${hexToRgb(color.black)}, 0.2);
    background-color: ${color.grey.medium};
    color: ${color.white};
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(1px);
    box-shadow: 0 0.1rem 1rem rgba(${hexToRgb(color.black)}, 0.2);
  }
`;

export default Auth;
