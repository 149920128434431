import React from 'react';
import styled from 'styled-components';
import { color, transition } from 'utils/style/variables';

class InputTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
    };
  }

  removeTag = (i) => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
    this.props.remove(i);
  };

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === ' ' && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...this.state.tags, val] });
      this.props.push(val);
      this.tagInput.value = null;
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  leaveItem = (e) => {
    const val = e.target.value;
    if (val && val !== ' ') {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...this.state.tags, val] });
      this.props.push(val);
      this.tagInput.value = null;
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  render() {
    const { tags } = this.state;

    return (
      <StyledInputTag>
        <TagsGroup>
          {tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <Button
                type="button"
                onClick={() => {
                  this.removeTag(i);
                }}
              >
                +
              </Button>
            </li>
          ))}
          <TagsItem>
            <input
              type="text"
              onKeyDown={this.inputKeyDown}
              onBlur={this.leaveItem}
              ref={(c) => {
                this.tagInput = c;
              }}
            />
          </TagsItem>
        </TagsGroup>
      </StyledInputTag>
    );
  }
}

const StyledInputTag = styled.div`
  background: white;
  border: 1px solid ${color.grey.c200};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  :hover {
    border: 1px solid ${color.grey.c500};
    transition: ${transition.default};
  }
  input {
    border: none;
    width: 100%;
    outline: 0 none;
  }
`;

const TagsGroup = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  li {
    align-items: center;
    background: ${color.primary.default};
    border-radius: 4px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin: 2px 5px 2px 2px;
    padding: 5px 10px;
  }
`;

const TagsItem = styled.li`
  background: none !important;
  flex-grow: 1;
  padding: 0;
`;

const Button = styled.button`
  align-items: center;
  appearance: none;
  background: ${color.white};
  border: none;
  border-radius: 50%;
  color: ${color.grey.medium};
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  transform: rotate(45deg);
  width: 15px;
`;

export default InputTag;
