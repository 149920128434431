import React from 'react';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { color } from 'utils/style/variables';
import { isNumber } from 'utils/functions';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.field.initialValue,
    };
  }

  changeHandler = event => {
    this.setState({ value: event.target.value });
    this.props.form.setFieldValue(this.props.field.name, event.target.value);
  };

  render() {
    const {
      field,
      form: { errors, values },
    } = this.props;

    const maxValue = isNumber(field.config.max)
      ? field.config.max
      : values[field.config.max];

    return (
      <StyledInputGroup>
        <StyledSlider>
          <input
            className="form-control-range"
            type="range"
            value={this.state.value}
            min={field.config.min}
            max={maxValue}
            step={field.config.step}
            onChange={this.changeHandler}
            error={errors[field.name]}
          />
          <Value>{this.state.value}</Value>
        </StyledSlider>
      </StyledInputGroup>
    );
  }
}

const StyledInputGroup = styled(InputGroup)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledSlider = styled.div`
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: 38px;
  outline: none;
  width: 100%;
  font-size: 1.2rem;
`;

const Value = styled.span`
  display: inline-block;
  position: relative;
  width: 60px;
  color: ${color.white};
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: ${color.primary.default};
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid ${color.primary.default};
    border-bottom: 7px solid transparent;
    content: '';
  }
`;

export default Slider;
