import { keyframes } from 'styled-components';

const color = {
  primary: {
    default: '#28ade2',
    light: '#92dce5',
    dark: '#304269',
    orange: '#f26101',
    green: '#b2c135',
    lighterblue: '#8398c6',
    // non approved colors
    espresso: '#7B2B0B',
    purple: '#659BF0',
    red: '#dd1c1a',
    forestgreen: '#808F34',
    midblue: '#4C74B2',
  },
  primaryRgba: {
    default: 'rgba(40,173,226,1)',
    light: 'rgba(146,220,229,1)',
    dark: 'rgba(48,66,105,1)',
    orange: 'rgba(242,97,1,1)',
    green: 'rgba(178,193,53,1)',
  },
  white: '#ffffff',
  black: '#000000',
  error: '#ff2721',
  success: '#828f32',
  grey: {
    light: '#e8e8e8',
    medium: '#727373',
    dark: '#474848',
    bg: '#f2f3f8',
    c100: '#f8f9fa',
    c200: '#e9ecef',
    c300: '#dee2e6',
    c400: '#ced4da',
    c500: '#adb5bd',
    c600: '#6c757d',
    c700: '#495057',
    c800: '#343a40',
    c900: '#212529',
  },
};

const shadow = {
  dark: '0 0 4rem 0 rgba(48, 66, 105, 0.2)',
  card: '0 0 13px 0 rgba(48, 66, 105, 0.05)',
  'element-hover': '0 0 5rem 0 rgba(48, 66, 105, 0.2)',
};
// box-shadow: 0px 0px 50px 0px rgba(${color.primary.dark}, 0.15);

const transition = {
  default: 'all 0.3s',
  one: 'all 1s',
};

const font = {
  size: '1.2rem',
  default: 'Arial, "Helvetica", Helvetica Neue, sans-serif',
  gotham: '"Gotham Book", Arial, sans-serif',
  gothamThin: '"Gotham Thin", Arial, sans-serif',
  gothamLight: '"Gotham Light", Arial, sans-serif',
  gothamMedium: '"Gotham Medium", Arial, sans-serif',
  gothamBold: '"Gotham Bold", Arial, sans-serif',
};

const breakpoint = {
  up: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: 'min-width: 1200px',
  },
  down: {
    sm: 'max-width: 575.98px',
    md: 'max-width: 767.98px',
    lg: 'max-width: 991.98px',
    xl: 'max-width: 1199.98px',
  },
};

const contrastThreshold = 150;

const animation = {
  menu: {
    fadeIn: keyframes` 
    from   { opacity: 0; }
    to { opacity: 1; }
    `,
    moveDown: keyframes`
    from   { margin-top: -10px }
    to { margin-top: 0; }
    `,
    moveUp: keyframes`
    from   { margin-top: 10px }
    to { margin-top: 0; }
    `,
  },
};

export {
  color,
  shadow,
  transition,
  font,
  breakpoint,
  contrastThreshold,
  animation,
};
