import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { color, animation, shadow } from 'utils/style/variables';
import { Nav, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubNav = props => {
  const Items = props.config.map(item => {
    if (item.divider) {
      return <Divider key="divider">External</Divider>;
    }

    if (item.active === false) {
      return (
        <InactiveLink key="item.title">
          <Icon icon={item.icon} />
          {item.title}
        </InactiveLink>
      );
    }

    return (
      <Nav.Item key={item.link}>
        {item.external ? (
          <ExternalLink href={item.link} target="_blank">
            {item.title}
          </ExternalLink>
        ) : (
          <Link activeClassName="active" to={item.link}>
            <Icon icon={item.icon} />
            {item.title}
          </Link>
        )}
      </Nav.Item>
    );
  });

  return (
    <Container>
      <Nav className="flex-column">{Items}</Nav>
    </Container>
  );
};

const Container = styled(Card)`
  position: absolute;
  left: 0;
  top: 57px;
  width: 27.5rem;
  border: none;
  padding: 1rem 0;
  border-radius: 0 0 4px 4px;
  box-shadow: ${shadow.card};
  animation: ${animation.menu.fadeIn} 0.3s ease 1,
    ${animation.menu.moveUp} 0.3s ease-out 1;
`;

const Link = styled(NavLink)`
  border: none;
  font-size: 1.2rem;
  color: ${color.grey.medium} !important;
  display: block;
  padding: 0.5rem 2rem;
  text-decoration: none;
  &:hover,
  &.${props => props.activeClassName} {
    color: ${color.primary.default} !important;
    background-color: ${color.grey.bg};
    text-decoration: none;
  }
`;

const InactiveLink = styled.div`
  border: none;
  font-size: 1.2rem;
  color: ${color.grey.c500} !important;
  display: block;
  padding: 0.5rem 2rem;
  text-decoration: none;
`;

const ExternalLink = styled.a`
  border: none;
  font-size: 1.2rem;
  color: ${color.grey.medium} !important;
  display: block;
  padding: 0.5rem 2rem;
  text-decoration: none;
  &:hover {
    color: ${color.primary.default} !important;
    background-color: ${color.grey.bg};
    text-decoration: none;
  }
`;

const Divider = styled.div`
  display: table;
  white-space: nowrap;
  overflow: hidden;
  &:before,
  &:after {
    border-top: 1px solid ${color.grey.c300};
    content: '';
    display: table-cell;
    position: relative;
    top: 0.8em;
    width: 45%;
  }
  &:before {
    right: 1.5%;
  }
  &:after {
    left: 1.5%;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

export default SubNav;
