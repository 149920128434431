import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import MainNav from './MainNav/MainNav';
import UserNav from './UserNav/UserNav';
import Logo from './Logo/Logo';
import classes from './Header.module.scss';

// import { menuToggleHandler } from 'utils/functions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCampaignSubnav: false,
      showSupplySubnav: false,
      showReportingSubnav: false,
      showOptimisationSubnav: false,
      showNotifications: false,
      showUserDetails: false,
    };
    this.menuOpenHandler = this.menuOpenHandler.bind(this);
    this.menuCloseHandler = this.menuCloseHandler.bind(this);
  }

  // TODO: possibly simplify this
  // rework this to basically just use css maybe?
  menuOpenHandler(event, type) {
    event.preventDefault();

    if (type === 'user') {
      this.setState({ showUserDetails: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }

    if (type === 'notifications') {
      this.setState({ showNotifications: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }

    if (type === 'reportsub') {
      this.setState({ showReportingSubnav: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }

    if (type === 'optisub') {
      this.setState({ showOptimisationSubnav: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }

    if (type === 'campsub') {
      this.setState({ showCampaignSubnav: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }

    if (type === 'supsub') {
      this.setState({ showSupplySubnav: true }, () => {
        document.addEventListener('click', this.menuCloseHandler);
      });
    }
  }

  menuCloseHandler() {
    this.setState(
      {
        showNotifications: false,
        showUserDetails: false,
        showReportingSubnav: false,
        showOptimisationSubnav: false,
        showCampaignSubnav: false,
        showSupplySubnav: false,
      },
      () => {
        document.removeEventListener('click', this.menuCloseHandler);
      }
    );
  }

  render() {
    return (
      <Container className={classes.Header}>
        <Row className={classes.Row}>
          <Col xs={12} sm={12} md="auto" lg="auto">
            <Logo />
          </Col>
          {/* <Col> */}
          {/* <button */}
          {/* onClick={event => */}
          {/* menuToggleHandler(event, this, 'showReportingSubnav') */}
          {/* } */}
          {/* > */}
          {/* click me */}
          {/* </button> */}
          {/* </Col> */}
          <Col>
            <MainNav
              showCampaignSubnav={this.state.showCampaignSubnav}
              showReportingSubnav={this.state.showReportingSubnav}
              showOptimisationSubnav={this.state.showOptimisationSubnav}
              showSupplySubnav={this.state.showSupplySubnav}
              clickedCampaign={event => this.menuOpenHandler(event, 'campsub')}
              clickedReporting={e => this.menuOpenHandler(e, 'reportsub')}
              clickedOptimisation={e => this.menuOpenHandler(e, 'optisub')}
              clickedSupply={event => this.menuOpenHandler(event, 'supsub')}
            />
          </Col>
          <Col xs={12} sm={12} md="auto" lg="auto">
            <UserNav
              showNotifications={this.state.showNotifications}
              show={this.state.showUserDetails}
              clicked={event => this.menuOpenHandler(event, 'user')}
              clickedNotifications={event =>
                this.menuOpenHandler(event, 'notifications')
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Header;
