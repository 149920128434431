import React from 'react';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { Col, Container, Popover, OverlayTrigger } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import { shadow, color, transition, font } from 'utils/style/variables';

import SingleDatePicker from 'components/Form/SingleDatePicker/SingleDatePicker';
import Select from 'components/Form/Select/Select';
import Currency from 'components/Form/Currency/Currency';
import Slider from 'components/Form/Slider/Slider';
import InputTag from 'components/Form/InputTag/InputTag';

import Spinner from 'components/UI/Spinner/Spinner';
import Button from 'components/UI/Button/Button';

import { createJobId } from 'utils/functions';

class XaxisForm extends React.Component {
  state = {
    response: {
      error: null,
      message: '',
      jobId: '',
    },
    hasSubmitted: false,
    submitting: false,
  };

  submitHandler = (values, { setSubmitting }) => {
    this.props.controls.submit(values);
    setSubmitting(false);
    this.props.setSubmitted();
  };

  initialValues = this.props.controls.fields.reduce(
    (obj, field) => {
      return {
        ...obj,
        [field.name]: field.initialValue,
      };
    },
    { jobId: createJobId() }
  );

  render() {
    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const content = (
      <Formik
        // this should enable the form re-render if the the initial values are changed
        initialValues={this.initialValues}
        validationSchema={this.props.controls.validation}
        onSubmit={this.submitHandler}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => {
          const fields = this.props.controls.fields.map((field) => {
            if (field.type === null) {
              return null;
            }

            // default field - if anything other than select or date
            let inputField = (
              <StyledField
                name={field.name}
                type={field.type}
                // required={field.required}
              />
            );

            if (field.type === 'select') {
              inputField = (
                <Field
                  component={Select}
                  field={field}
                  // required={field.required}
                />
              );
            }

            if (field.type === 'slider') {
              inputField = (
                <Field
                  component={Slider}
                  field={field}
                  // required={field.required}
                />
              );
            }

            if (field.type === 'date') {
              inputField = (
                <Field
                  component={SingleDatePicker}
                  field={field}
                  // required={field.required}
                />
              );
            }

            if (field.type === 'currency') {
              inputField = (
                <Field
                  field={field}
                  component={Currency}
                  onChange={setFieldValue}
                  // required={field.required}
                />
              );
            }

            if (field.type === 'tags') {
              inputField = (
                <FieldArray
                  name={field.name}
                  component={InputTag}
                  // required={field.required}
                />
              );
            }

            let popover = (
              <StyledPopover id={field.name + '_popover'}>
                <Popover.Title as="h3">
                  {field.config.placeholder}
                </Popover.Title>
                <Popover.Content>{field.config.explanation}</Popover.Content>
              </StyledPopover>
            );

            let label = (
              <>
                {field.config.placeholder}{' '}
                {field.required ? <Required>*</Required> : null}
              </>
            );

            let row = (
              <Row key={field.name}>
                <Col md={4}>
                  {field.config.explanation ? (
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      overlay={popover}
                    >
                      <LabelWithPopover>
                        {label}
                        <Icon icon={faQuestionCircle} />
                      </LabelWithPopover>
                    </OverlayTrigger>
                  ) : (
                    <Label>{label}</Label>
                  )}
                </Col>
                <Col md={8}>
                  {inputField}
                  <StyledErrorMessage
                    name={field.name}
                    className={['alert', 'alert-danger'].join(' ')}
                    component="div"
                  />
                </Col>
              </Row>
            );

            return row;
          });

          return (
            <React.Fragment>
              <Form className="form-group">
                <Row>
                  <RequiredNote>
                    {/* Fields marked with a <Required>*</Required> are required. */}
                    <Required>*</Required> = required |{' '}
                    <Icon icon={faQuestionCircle} />
                    &nbsp;= Hover for more information
                  </RequiredNote>
                </Row>
                <Row>
                  <Col>{fields}</Col>
                </Row>
                <Row>
                  <ButtonCol>
                    <Button type="submit" disabled={isSubmitting} primary>
                      Submit
                    </Button>
                  </ButtonCol>
                </Row>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );

    return this.state.submitting ? spinnerContainer : content;
  }
}

const StyledField = styled(Field)`
  padding: 0.65rem 1rem;
  box-shadow: none;
  min-height: 38px;
  outline: none;
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid ${color.grey.c200};
  border-radius: 4px;
  transition: ${transition.default};

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.grey.c500};
  }
`;

const Label = styled.label`
  display: flex;
  padding-right: 1rem;
  padding-left: 0.5rem;
  width: 100%;
  min-height: 38px;
  align-items: center;
  border-radius: 4px;
  transition: ${transition.default};
`;

const LabelWithPopover = styled(Label)`
  :hover {
    background-color: ${color.grey.c100};
    border-radius: 4px;
    transition: ${transition.default};
  }
`;

const StyledPopover = styled(Popover)`
  box-shadow: ${shadow.card};
  font-size: ${font.size};
  h3 {
    font-size: ${font.size};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

const Required = styled.span`
  color: red;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const RequiredNote = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.65rem 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-top: 0.2rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: none;
  margin-left: 6px;
  width: 90%;
  transition: ${transition.default};
`;

const ButtonCol = styled(Col)`
  padding-top: 0.51rem;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

export default XaxisForm;
