import React from 'react';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Container, Popover, OverlayTrigger } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
// import { Row } from 'react-bootstrap/Form';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import { color, shadow, transition, font } from 'utils/style/variables';
import TemplatePreviewXaxis from 'assets/img/template_xaxis.png';
import TemplatePreviewBlank from 'assets/img/template_blank.png';
import TemplatePreviewGroupm from 'assets/img/template_groupm.png';
import TemplatePreviewMediacom from 'assets/img/template_mediacom.png';
import TemplatePreviewMindshare from 'assets/img/template_mindshare.png';
import TemplatePreviewWavemaker from 'assets/img/template_wavemaker.png';

import DateRangePicker from 'components/Form/DateRangePicker/DateRangePicker';
import Select from 'components/Form/Select/Select';
import Currency from 'components/Form/Currency/Currency';
import Spinner from 'components/UI/Spinner/Spinner';
import Button from 'components/UI/Button/Button';
import InputTag from 'components/Form/InputTag/InputTag';
import { createJobId } from 'utils/functions';

class XaxisForm extends React.Component {
  state = {
    response: {
      error: null,
      message: '',
      id: '',
    },
    hasSubmitted: false,
    submitting: false,
  };

  isOutsideRange = (day) => day >= moment();

  submitHandler = (values, { setSubmitting }) => {
    this.props.controls.submit(values);
    setSubmitting(false);
    this.props.setSubmitted();
  };

  isOutsideRange = (day) => day >= moment();

  initialValues = this.props.controls.fields.reduce(
    (obj, field) => {
      return {
        ...obj,
        [field.name]: field.initialValue,
      };
    },
    { id: createJobId() }
  );

  // this.props.typeUpdateHandler(values.outcomeType.value);

  render() {
    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const content = (
      <Formik
        // this should enable the form re-render if the the initial values are changed
        initialValues={this.initialValues}
        validationSchema={this.props.controls.validation}
        onSubmit={this.submitHandler}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => {
          const fields = this.props.controls.fields.map((field) => {
            if (field.type === null) {
              return null;
            }

            // default field - if anything other than select or date
            let inputField = (
              <StyledField name={field.name} type={field.type} />
            );

            if (field.type === 'select') {
              inputField = <Field component={Select} field={field} required />;
            }

            if (field.type === 'date' && field.config.dateRange === true) {
              inputField = (
                <Field
                  isOutsideRange={this.isOutsideRange}
                  component={DateRangePicker}
                  required
                />
              );
            }

            if (field.type === 'date' && field.config.dateRange === false) {
              // different one
              // inputField = <Field component={DateRangePicker} required />;
              inputField = <StyledField name={field.name} type={field.type} />;
            }

            if (
              field.name === 'outcomeGoal' &&
              values.outcomeType.value === 'CONVERSION'
            ) {
              inputField = (
                <Field
                  field={field}
                  component={Currency}
                  onChange={setFieldValue}
                  required
                />
              );
            }

            if (field.type === 'tags') {
              inputField = (
                <FieldArray
                  name={field.name}
                  component={InputTag}
                  // required={field.required}
                />
              );
            }

            // change outcome goal label depending on outcome type choice
            if (field.name === 'outcomeGoal') {
              switch (values.outcomeType.value) {
                case 'VIEWABILITY':
                  field.config.placeholder = 'Outcome Goal (in %)';
                  break;
                case 'VIDEO_COMPLETION_DATE':
                  field.config.placeholder = 'Outcome Goal (in %)';
                  break;
                case 'CONVERSION':
                  field.config.placeholder =
                    'Outcome Goal (advertiser currency)';
                  break;
                default:
                  field.config.placeholder = 'Outcome Goal';
              }
            }

            let popover = (
              <StyledPopover id={field.name + '_popover'}>
                <Popover.Title as="h3">
                  {field.config.placeholder}
                </Popover.Title>
                <Popover.Content>{field.config.explanation}</Popover.Content>
              </StyledPopover>
            );

            let label = (
              <>
                {field.config.placeholder}{' '}
                {field.required ? <Required>*</Required> : null}
              </>
            );

            let row = (
              <Row key={field.name}>
                <Col md={4}>
                  {field.config.explanation ? (
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      overlay={popover}
                    >
                      <LabelWithPopover>
                        {label}
                        <Icon icon={faQuestionCircle} />
                      </LabelWithPopover>
                    </OverlayTrigger>
                  ) : (
                    <Label>{label}</Label>
                  )}
                </Col>
                <Col md={8}>
                  {inputField}
                  <StyledErrorMessage
                    name={field.name}
                    className={['alert', 'alert-danger'].join(' ')}
                    component="div"
                  />
                </Col>
              </Row>
            );

            // don't show outcomeGoal if outcome type is targeted reach
            if (field.name === 'outcomeGoal') {
              row = values.outcomeType.value !== 'TARGETED_REACH' ? row : null;
            }

            // only show pixelId input if outcome type is conversion
            if (field.name === 'pixelId') {
              row = values.outcomeType.value === 'CONVERSION' ? row : null;
            }

            // only show pan regional country select input if pan regional seat is chosen
            if (field.name === 'panRegionalCountry') {
              // row =
              // values.seatId.value === 1845
              // ? row
              // : values.seatId.value === 7942
              // ? row
              // : null;
              row = values.seatId.value === 9999 ? row : null;
            }

            return row;
          });

          let previewImage;
          let previewImageAlt;

          switch (values.template.value) {
            case 'BLANK':
              previewImage = TemplatePreviewBlank;
              previewImageAlt = 'Blank Template Preview';
              break;
            case 'GROUPM':
              previewImage = TemplatePreviewGroupm;
              previewImageAlt = 'GroupM Template Preview';
              break;
            case 'MEDIACOM':
              previewImage = TemplatePreviewMediacom;
              previewImageAlt = 'Mediacom Template Preview';
              break;
            case 'MINDSHARE':
              previewImage = TemplatePreviewMindshare;
              previewImageAlt = 'Mindshare Template Preview';
              break;
            case 'WAVEMAKER':
              previewImage = TemplatePreviewWavemaker;
              previewImageAlt = 'Wavemaker Template Preview';
              break;
            default:
              previewImage = TemplatePreviewXaxis;
              previewImageAlt = 'Xaxis Template Preview';
          }

          return (
            <React.Fragment>
              <Form className="form-group">
                <Row>
                  <RequiredNote md={6}>
                    Fields marked with a <Required>*</Required> are required.
                  </RequiredNote>
                </Row>
                <Row>
                  <Col md={6}>{fields}</Col>
                  <TemplatePreviewContainer md={6}>
                    <TemplatePreview src={previewImage} alt={previewImageAlt} />
                  </TemplatePreviewContainer>
                </Row>
                <Row>
                  <ButtonCol>
                    <Button type="submit" disabled={isSubmitting} primary>
                      Submit
                    </Button>
                  </ButtonCol>
                </Row>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );

    return this.state.submitting ? spinnerContainer : content;
  }
}

const StyledField = styled(Field)`
  padding: 0.65rem 1rem;
  box-shadow: none;
  min-height: 38px;
  outline: none;
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid ${color.grey.c200};
  border-radius: 4px;
  transition: ${transition.default};

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${color.grey.c500};
  }
`;

const StyledPopover = styled(Popover)`
  box-shadow: ${shadow.card};
  font-size: ${font.size};
  h3 {
    font-size: ${font.size};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;

const Label = styled.label`
  display: flex;
  padding-right: 1rem;
  width: 100%;
  min-height: 38px;
  align-items: center;
`;

const LabelWithPopover = styled(Label)`
  :hover {
    background-color: ${color.grey.c100};
    border-radius: 4px;
    transition: ${transition.default};
  }
`;

const Required = styled.span`
  color: red;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const RequiredNote = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.65rem 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-top: 0.2rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: none;
  margin-left: 6px;
  width: 90%;
  transition: ${transition.default};
`;

const ButtonCol = styled(Col)`
  padding-top: 0.51rem;
`;

const TemplatePreview = styled.img`
  width: 40rem;
  box-shadow: ${shadow.card};
  border-radius: 4px;
  transition: ${transition.default};
  margin: 2rem 1rem 2rem 1rem;
`;

const TemplatePreviewContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

export default XaxisForm;
