import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import styled from 'styled-components';
import { color } from 'utils/style/variables';
import Form from './Form/Form';
import seats from './seatIdsYoutubs';
import scrabbleImage from '../../../assets/img/scrabble.jpg';
import ResponseMessage from 'components/ResponseMessage/ResponseMessage';
import Subheader from 'components/Subheader/Subheader';
import Spinner from 'components/UI/Spinner/Spinner';
import api from 'axios-instance';
import './css/IAB.css';

export class ContextualSegments extends React.Component {
  constructor(props) {
    super(props);
    let seats = [];
    if (this.props.responseDatas !== undefined) {
      for (var i = 0; i <= this.props.responseDatas.length - 1; i++) {
        if (this.props.responseDatas[i].Tool == 'Contextual IAB') {
          var c = {};
          c.value = this.props.responseDatas[i].SeatID;
          c.label = this.props.responseDatas[i].SeatName;
          if (this.props.responseDatas[i].IsActive == 'Yes') {
            c.isDisabled = false;
          } else {
            c.isDisabled = true;
          }
          seats.push(c);
        }
      }
    }
    this.state = {
      version: '0.1.2',
      form: {
        fields: [
          {
            name: 'seatId',
            initialValue: '',
            type: 'select',
            required: true,
            config: {
              placeholder: 'Seat ID',
              options: seats,
            },
          },
        ],
        validation: this.validationSchema,
        submit: this.submitHandler.bind(this),
      },
      responseData: [],
      hasSubmitted: false,
      submitting: false,
    };
  }

  validationSchema = Yup.object({
    seatId: Yup.string().required('Choosing an Seat ID is required.'),
  });

  createFormData = (values, email) => {
    var insertionOrderId = values.insertionOrderId;

    return {
      seatId: values.seatId.value,
      userEmail: email,
    };
  };

  submitHandler = (values) => {
    this.setState({ submitting: true });
    const formData = this.createFormData(values, this.props.email);
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.postDataHandler(formData);
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log('error trying to receive currentAuthenticatedUser', error);
      });
  };

  postDataHandler = (formData) => {
    api
      .post('/IABContextual/getData', formData)
      .then((response) => {
        this.setState({
          submitting: false,
          responseData: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          responseMessage: error.message,
          responseError: true,
          submitting: false,
        });
      });
  };

  logoutHandler = () => {
    Auth.signOut({ global: true })
      .then((data) => console.log('signing out', data))
      .catch((err) => console.log('signOut error', err));
  };

  setSubmitted = () => this.setState({ hasSubmitted: true });

  resetForm = () => {
    this.setState({
      hasSubmitted: false,
      responseMessage: '',
      responseData: '',
      responseError: null,
    });
  };

  render() {
    const subheader = {
      main: 'Campaign Setup ',
      levels: [
        {
          name: 'Contextual IAB Segments ',
          link: '/campaignsetup/iab-contextual-segments',
        },
      ],
    };

    const spinnerContainer = (
      <Container>
        <Row>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Row>
      </Container>
    );

    const response = this.state.submitting ? (
      spinnerContainer
    ) : (
      <ResponseMessage
        resetForm={this.resetForm}
        config={this.state.response}
      />
    );

    return (
      <>
        <Subheader config={subheader} />

        <StyledContainer>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xl={3} lg={3} md={3} sm={12}>
                <DashboardCard className="greyc500 second">
                  <CardImg variant="top" src={scrabbleImage} />
                  <Card.Body>
                    <CardTitle>
                      <b> Contextual IAB Segments</b>
                    </CardTitle>
                    <Card.Text>
                      Contextual IAB Segments are made using data from pages
                      that have had impressions served on them. They are based
                      on the IAB taxonomy. Each segment contains pages that are
                      relevant to the IAB content category. Use{' '}
                      <a
                        href="/reporting/contextual-insights"
                        alt="Campaign Explorer YouTube Intro Deck"
                      >
                        Contextual Insights
                      </a>{' '}
                      to get insights on what content category segments are
                      likely to increase performance of your campaign. Then
                      apply relevant Contextual IAB Segments to your campaign.
                    </Card.Text>
                  </Card.Body>
                </DashboardCard>
              </Col>
              <Col xl={9} lg={9} md={9} sm={12}>
                <Card className="xtCard">
                  <Card.Body>
                    <h1>
                      <b>IAB contextual segments</b>
                    </h1>
                    <React.Fragment>
                      <p>
                        Select Your SeatID to generate a list of available
                        segments{' '}
                      </p>
                      {
                        <Form
                          setSubmitted={this.setSubmitted}
                          controls={this.state.form}
                        />
                      }
                    </React.Fragment>

                    {this.state.hasSubmitted &&
                    this.state.responseData !== '' ? (
                      <Table striped bordered hover>
                        <thead>
                          <tr className="tabletr">
                            <th>Segment Name</th>
                            <th>Segment Id </th>
                            <th>Reach</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.responseData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.segment_name}</td>
                              <td>{item.segment_id}</td>
                              <td>{item.reach}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}

                    <p>
                      To find out more about IAB Contextual Segments please read the documentation available below:
                    </p>
                    <p>
                      <a
                        href="/assets/files/ContextualIabSegments.pdf"
                        alt="Campaign Explorer YouTube Intro Deck"
                        target="_blank"
                      >
                        Contextual IAB Segments
                      </a>{' '}
                      {'\n'}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Version>v{this.state.version}</Version>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    email: state.auth.userEmail,
    responseDatas: state.auth.Data,
  };
};

const StyledContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  /* TODO: might need to move this up to layout */
  min-width: 30rem;
  z-index: 1;
`;

const Version = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerContainer = styled(Col)`
  color: ${color.black};
  background-color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem;
  padding-top: 3rem;
`;

const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 13px 0px rgba($color-primary-dark, 0.05);
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  min-height: 150px;
  font-size: 1.2rem;
  &.first {
    min-height: 150px;
  }
  &.second {
    min-height: 340px;
  }
  &.carousel {
    min-height: 370px;
  }
  &.defaultBlue {
    background-color: ${color.primary.default};
    color: white;
  }
  &.darkBlue {
    background-color: ${color.primary.dark};
    color: white;
  }
  &.lightBlue {
    background-color: ${color.primary.light};
    color: white;
  }
  &.espresso {
    background-color: ${color.primary.espresso};
    color: white;
  }
  &.lighterBlue {
    background-color: ${color.primary.lighterblue};
    color: white;
  }

  &.orange {
    background-color: ${color.primary.orange};
    color: white;
  }
  &.green {
    background-color: ${color.primary.green};
    color: white;
  }
  &.greyMedium {
    background-color: ${color.grey.medium};
    color: white;
  }
  &.greyc500 {
    background-color: ${color.grey.c500};
    color: white;
  }
`;

const CardTitle = styled(Card.Title)`
  font-size: 1.6.rem;
  font-weight: bold;
`;

const CardImg = styled(Card.Img)`
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  height: 163.33px;
`;

export default connect(mapStateToProps)(ContextualSegments);
